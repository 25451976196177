// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_banner {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
}

.login_banner.fit {
    height: -moz-fit-content;
    height: fit-content;
}

.login_banner h2 {
    color: gray;
}
.login_banner h1 {
    color: white;
}

.login_form {
    width: 100%;
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 15px;
    align-items: end;
}

.login_form_text_container {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/login_page/Login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":[".login_banner {\n    width: 100%;\n    height: 100%;\n    padding: 15px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    background-color: black;\n}\n\n.login_banner.fit {\n    height: fit-content;\n}\n\n.login_banner h2 {\n    color: gray;\n}\n.login_banner h1 {\n    color: white;\n}\n\n.login_form {\n    width: 100%;\n    height: 100%;\n    max-width: 300px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 15px;\n    gap: 15px;\n    align-items: end;\n}\n\n.login_form_text_container {\n    width: 100%;\n    height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
