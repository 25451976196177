// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset_image_container {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    display: flex;
    background-color: whitesmoke;
}

.asset_image_container.empty {
    aspect-ratio: 2;
    height: 200px;
    width: auto;
}

.asset_image_icon {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    pointer-events: none;
    width: 30%;
    height: 30%;
    color: lightgray;
}

.asset_image {
    width: auto;
    max-width: 100%;
    height: 200px;
    max-height: 200px;
    object-fit: contain;
    transition: opacity 0.2s ease;
}

.asset_image.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
}

.asset_detail_data_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/asset_page/asset_detail_modal/AssetDetailModal.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,aAAa;IACb,WAAW;AACf;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,UAAU;IACV,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,0DAA0D;IAC1D,SAAS;AACb","sourcesContent":[".asset_image_container {\n    width: fit-content;\n    height: fit-content;\n    position: relative;\n    display: flex;\n    background-color: whitesmoke;\n}\n\n.asset_image_container.empty {\n    aspect-ratio: 2;\n    height: 200px;\n    width: auto;\n}\n\n.asset_image_icon {\n    width: fit-content;\n    height: fit-content;\n    position: absolute;\n    pointer-events: none;\n    width: 30%;\n    height: 30%;\n    color: lightgray;\n}\n\n.asset_image {\n    width: auto;\n    max-width: 100%;\n    height: 200px;\n    max-height: 200px;\n    object-fit: contain;\n    transition: opacity 0.2s ease;\n}\n\n.asset_image.hidden {\n    opacity: 0;\n    visibility: hidden;\n    transition: opacity 0.2s ease;\n}\n\n.asset_detail_data_container {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
