import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationContext from '../../../providers/NotificationProvider';
import useWindowSize from '../../../providers/WindowProvider';
import LocalStorage from '../../../constants/LocalStorageConstants';
import AuthService from '../../../services/AuthService';
import UserService from '../../../services/UserService';
import TextInput from '../../common/text_input/TextInput';

import './Login.css';

function Login() {
    const navigate = useNavigate();
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);
    const windowSize = useWindowSize();
    const isVertical = windowSize.width < 1000;
    const [vertical, setVertical] = useState(isVertical);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        setVertical(isVertical);
    }, [isVertical]);

    const login = async () => {
        const notificationId = raiseNotification('Logging in...', {
            permanent: true,
        });
        try {
            await AuthService.getAccessToken(email, password);
            const response = await UserService.getUserById(
                localStorage[LocalStorage.USER_ID]
            );
            localStorage[LocalStorage.USER_NAME] = response.data['name'];
            removeNotification(notificationId);
            raiseNotification('Login successful.', {
                type: 'success',
            });
            navigate('/');
        } catch (error) {
            removeNotification(notificationId);
            raiseNotification('Failed to log in.', {
                type: 'error',
            });
        }
    };

    return (
        <div className={vertical ? 'page vertical' : 'page horizontal'}>
            <div className="page_section center">
                <div className="login_banner">
                    <h2>This is</h2>
                    <h1>Pocket Factory AB</h1>
                </div>
            </div>
            <div className="page_section center flex">
                <div className="login_form">
                    <div className="login_form_text_container">
                        <h2>Welcome</h2>
                    </div>
                    <TextInput
                        id="email"
                        placeholder="Email"
                        onChange={setEmail}
                    />
                    <TextInput
                        id="password"
                        placeholder="Password"
                        onChange={setPassword}
                        password
                    />
                    <button className="solid_button blue" onClick={login}>
                        Log in
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;
