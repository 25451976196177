import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import TypeClassService from '../../../services/TypeClassService';
import ModifierService from '../../../services/ModifierService';
import StateConstants from '../../../constants/StateConstants';
import ClipboardUtils from '../../../utils/ClipboardUtils';
import useWindowSize from '../../../providers/WindowProvider';
import EntityService from '../../../services/EntityService';
import StateContext from '../../../providers/StateProvider';
import AssetService from '../../../services/AssetService';
import SkillService from '../../../services/SkillService';
import EntityUtils from '../../../utils/EntityUtils';
import ErrorUtils from '../../../utils/ErrorUtils';
import EntityForm from './EntityForm';
import EntityList from './EntityList';

function Entity() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const entityStates = StateConstants.EntityStates;
    const [loadingTypeClasses, setLoadingTypeClasses] = useState(true);
    const [loadingModifiers, setLoadingModifiers] = useState(true);
    const [loadingEntities, setLoadingEntities] = useState(true);
    const [loadingAssets, setLoadingAssets] = useState(true);
    const [loadingSkills, setLoadingSkills] = useState(true);
    const formData = EntityUtils.createEntityDTO(
        state[entityStates.NAME],
        state[entityStates.DESC_KEY],
        state[entityStates.PRICE],
        state[entityStates.TEAM_ID],
        state[entityStates.SIZE_SCALE],
        state[entityStates.HEALTH_POINTS],
        state[entityStates.MOVEMENT_SPEED],
        state[entityStates.BASE_HP_REGEN],
        state[entityStates.ARMOUR],
        state[entityStates.ARMOUR_TYPE_ID],
        state[entityStates.BASE_ATTACK_TYPE],
        state[entityStates.BASE_ATTACK_RANGE],
        state[entityStates.BASE_ATTACK_SPEED],
        state[entityStates.BASE_ATTACK_PROJECTILE_SPEED],
        state[entityStates.BASE_ATTACK_DAMAGE],
        state[entityStates.BASE_ATTACK_DAMAGE_TYPE_ID],
        state[entityStates.SKILL_IDS],
        state[entityStates.MODIFIER_IDS],
        Object.values(state[entityStates.GROUPED_ASSET_IDS]).flat()
    );

    // Functions
    const getEntities = async () => {
        setLoadingEntities(true);
        await EntityService.getAllEntities()
            .then((response) => {
                setState(commonStates.ENTITIES, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingEntities(false);
    };

    const getSkills = async () => {
        setLoadingSkills(true);
        await SkillService.getAllSkills()
            .then((response) => {
                setState(commonStates.SKILLS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingSkills(false);
    };

    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setLoadingAssets(false);
    };

    const getModifiers = async () => {
        setLoadingModifiers(true);
        await ModifierService.getAllModifiers()
            .then((response) => setState(commonStates.MODIFIERS, response.data))
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingModifiers(false);
    };

    const getTypeClasses = async () => {
        setLoadingTypeClasses(true);
        await TypeClassService.getAllTypeClasses()
            .then((response) => {
                setState(commonStates.TYPE_CLASSES, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingTypeClasses(false);
    };

    const createEntity = async () => {
        const notificationId = raiseNotification('Creating entity...', {
            permanent: true,
        });
        await EntityService.createEntity(formData)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Entity was created. ID: ${response.data['id']}`,
                    {
                        type: 'success',
                    }
                );
                getEntities();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const copyEntity = async () => {
        try {
            await ClipboardUtils.copyToClipboard(
                JSON.stringify(formData, null, 4)
            );
            raiseNotification('Copied to clipboard.', {
                type: 'success',
            });
        } catch (error) {
            raiseNotification(ErrorUtils.getErrorMessage(error), {
                type: 'error',
            });
        }
    };

    // Startup
    useEffect(() => {
        getTypeClasses();
        getModifiers();
        getEntities();
        getSkills();
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <EntityForm
                createEntity={createEntity}
                copyEntity={copyEntity}
                loadingTypeClasses={loadingTypeClasses}
                loadingModifiers={loadingModifiers}
                loadingAssets={loadingAssets}
                loadingSkills={loadingSkills}
            />
            <EntityList loadingEntities={loadingEntities} />
        </div>
    );
}

export default Entity;
