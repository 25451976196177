import { createContext, useState } from 'react';
import { v4 } from 'uuid';

import {
    ToastNotification,
    ToastNotificationList,
} from '../components/common/toast_notification/ToastNotification';

const NotificationContext = createContext();

function NotificationProvider(props) {
    const [notifications, setNotifications] = useState([]);

    const removeNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    };

    const raiseNotification = (
        message,
        { type = 'info', permanent = false } = {}
    ) => {
        const notification = {
            id: v4(),
            type: type,
            message: message,
        };

        setNotifications((prevNotifications) => [
            ...prevNotifications,
            notification,
        ]);

        if (!permanent) {
            setTimeout(() => {
                removeNotification(notification.id);
            }, 5000);
        }

        return notification.id;
    };

    return (
        <NotificationContext.Provider
            value={{ raiseNotification, removeNotification }}
        >
            {notifications.length > 0 && (
                <ToastNotificationList>
                    {notifications.map((notification, index) => (
                        <ToastNotification
                            key={index}
                            id={notification.id}
                            type={notification.type}
                            message={notification.message}
                            onClose={() => removeNotification(notification.id)}
                        />
                    ))}
                </ToastNotificationList>
            )}
            {props.children}
        </NotificationContext.Provider>
    );
}

export { NotificationProvider };
export default NotificationContext;
