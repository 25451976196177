import { useContext } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import ClipboardUtils from '../../../utils/ClipboardUtils';
import MasterService from '../../../services/MasterService';
import MonacoEditor from '../../common/monaco_editor/MonacoEditor';
import ErrorUtils from '../../../utils/ErrorUtils';

function MasterEditor(props) {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Functions
    const copySelectedMaster = async () => {
        try {
            await ClipboardUtils.copyToClipboard(
                JSON.stringify(props.selected, null, 4)
            );
            raiseNotification(
                `Copied master data to clipboard. Version: ${props.selected['version']}`,
                { type: 'success' }
            );
        } catch (error) {
            raiseNotification(ErrorUtils.getErrorMessage(error), {
                type: 'error',
            });
        }
    };

    const createMaster = async () => {
        const notificationId = raiseNotification('Creating master...', {
            permanent: true,
        });
        await MasterService.createMaster()
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Master was created. Version: ${response.data['version']}`,
                    { type: 'success' }
                );
                props.getMasters();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    return (
        <div className="page_section divider_border">
            <div className="page_grid">
                <h2>Master Detail</h2>
                <MonacoEditor
                    height="600px"
                    language="json"
                    value={JSON.stringify(props.selected, null, 4)}
                    loading={props.loading}
                    readOnly={true}
                />
                <div className="page_row right wrap">
                    <button className="solid_button red" onClick={createMaster}>
                        Create New Master
                    </button>
                    <button
                        className="solid_button gray"
                        onClick={copySelectedMaster}
                    >
                        Copy Master as JSON
                    </button>
                    <button className="solid_button blue">Save</button>
                </div>
            </div>
        </div>
    );
}

export default MasterEditor;
