// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: whitesmoke;
}

.confirmation_box_text {
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}

.confirmation_box_buttons {
    flex: 1 1;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.confirmation_box_button {
    min-width: 70px;
    width: -moz-fit-content;
    width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/confirmation_box/ConfirmationBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;AACtB","sourcesContent":[".confirmation_box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: fit-content;\n    max-width: 100%;\n    gap: 10px;\n    padding: 10px;\n    border-radius: 8px;\n    background-color: whitesmoke;\n}\n\n.confirmation_box_text {\n    text-align: center;\n    word-wrap: break-word;\n    word-break: break-word;\n}\n\n.confirmation_box_buttons {\n    flex: 1;\n    display: flex;\n    gap: 5px;\n    align-items: center;\n    justify-content: center;\n}\n\n.confirmation_box_button {\n    min-width: 70px;\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
