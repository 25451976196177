import './Divider.css';

function Divider(props) {
    let className = 'divider';
    className += props.horizontal ? ' horizontal' : ' vertical';
    if (props.className) {
        className += ` ${props.className}`;
    }
    return <div className={className}></div>;
}

export default Divider;
