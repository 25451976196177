import {
    FaCheckCircle,
    FaExclamationCircle,
    FaExclamationTriangle,
    FaInfoCircle,
} from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

import './ToastNotification.css';

function ToastNotification(props) {
    function ToastNotificationIcon(props) {
        if (props.type === 'success') {
            return <FaCheckCircle className="notification_icon" />;
        } else if (props.type === 'warning') {
            return <FaExclamationTriangle className="notification_icon" />;
        } else if (props.type === 'error') {
            return <FaExclamationCircle className="notification_icon" />;
        } else {
            return <FaInfoCircle className="notification_icon" />;
        }
    }

    const setNotificationClassName = () => {
        return props.type ? `notification ${props.type}` : 'notification';
    };

    const setCloseIconClassName = () => {
        return ['success', 'error'].includes(props.type)
            ? 'close_icon white'
            : 'close_icon';
    };

    return (
        <div id={props.id} className={setNotificationClassName()}>
            <ToastNotificationIcon type={props.type && props.type} />
            <p>{props.message}</p>
            <FaXmark
                className={setCloseIconClassName()}
                onClick={props.onClose}
            />
        </div>
    );
}

function ToastNotificationList(props) {
    return (
        <div className="notification_list" aria-live="assertive">
            {props.children}
        </div>
    );
}

export { ToastNotificationList, ToastNotification };
