import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import ModifierService from '../../../services/ModifierService';
import StateConstants from '../../../constants/StateConstants';
import useWindowSize from '../../../providers/WindowProvider';
import ModifierUtils from '../../../utils/ModifierUtils';
import StateContext from '../../../providers/StateProvider';
import AssetService from '../../../services/AssetService';
import ModifierForm from './ModifierForm';
import ModifierList from './ModifierList';
import ErrorUtils from '../../../utils/ErrorUtils';

function Modifier() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const modifierStates = StateConstants.ModifierStates;
    const [loadingModifiers, setLoadingModifiers] = useState(true);
    const [loadingAssets, setLoadingAssets] = useState(true);

    // Functions
    const getModifiers = async () => {
        setLoadingModifiers(true);
        await ModifierService.getAllModifiers()
            .then((response) => {
                setState(commonStates.MODIFIERS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingModifiers(false);
    };

    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => {
                setState(commonStates.ASSETS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingAssets(false);
    };

    const createModifier = async () => {
        const notificationId = raiseNotification('Creating modifier...', {
            permanent: true,
        });
        const data = ModifierUtils.createModifierDTO(
            state[modifierStates.NAME],
            state[modifierStates.DESC_KEY],
            state[modifierStates.TYPE],
            state[modifierStates.EXPIRATION],
            state[modifierStates.VALUE],
            state[modifierStates.PRICE],
            Object.values(state[modifierStates.GROUPED_ASSET_IDS]).flat()
        );
        await ModifierService.createModifier(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Modifier was created. ID: ${response.data['id']}`,
                    {
                        type: 'success',
                    }
                );
                getModifiers();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    // Startup
    useEffect(() => {
        getModifiers();
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <ModifierForm
                createModifier={createModifier}
                loadingAssets={loadingAssets}
            />
            <ModifierList loadingModifiers={loadingModifiers} />
        </div>
    );
}

export default Modifier;
