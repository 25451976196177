import EnumerationConstants from '../../../constants/EnumerationConstants';
import LabelPill from '../../common/label_pill/LabelPill';
import Table from '../../common/table/Table';

function SkinList(props) {
    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Skin List</h2>
                <Table
                    key="id"
                    loading={props.loading}
                    columns={[
                        { header: 'ID', field: 'id' },
                        { header: 'Name', field: 'name' },
                        { header: 'Assets', field: 'assets' },
                        { header: 'Created At', field: 'createdAt' },
                    ]}
                    data={props.skins.map((item) => ({
                        id: item.id,
                        name: item.name,
                        assets: item.assets.map((asset, index) => (
                            <LabelPill
                                key={index}
                                label={
                                    EnumerationConstants.AssetTypes[
                                        asset.assetType
                                    ].label
                                }
                            />
                        )),
                        createdAt: item.createdAt,
                    }))}
                />
            </div>
        </div>
    );
}

export default SkinList;
