import { useContext } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormAssetFields from '../../common/form_asset_fields/FormAssetFields';
import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import TextInput from '../../common/text_input/TextInput';
import Dropdown from '../../common/dropdown/Dropdown';
import Divider from '../../common/divider/Divider';
import Drawer from '../../common/drawer/Drawer';
import Table from '../../common/table/Table';

const EntityForm = (props) => {
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const entityStates = StateConstants.EntityStates;
    const baseAttackDamageTypeId =
        state[entityStates.BASE_ATTACK_DAMAGE_TYPE_ID];
    const armourTypeId = state[entityStates.ARMOUR_TYPE_ID];

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Entity</h2>
                <FormFieldTitle title="Entity Name (String)">
                    <TextInput
                        value={state[entityStates.NAME]}
                        onChange={(value) => setState(entityStates.NAME, value)}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={state[entityStates.DESC_KEY]}
                        onChange={(value) =>
                            setState(entityStates.DESC_KEY, value)
                        }
                    />
                </FormFieldTitle>
                <div className="page_row bottom">
                    <FormFieldTitle title="Health Points (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.HEALTH_POINTS]}
                            onChange={(value) =>
                                setState(entityStates.HEALTH_POINTS, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Base HP Regeneration (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.BASE_HP_REGEN]}
                            onChange={(value) =>
                                setState(entityStates.BASE_HP_REGEN, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Size Scale (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.SIZE_SCALE]}
                            onChange={(value) =>
                                setState(entityStates.SIZE_SCALE, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Movement Speed (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.MOVEMENT_SPEED]}
                            onChange={(value) =>
                                setState(entityStates.MOVEMENT_SPEED, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Price (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.PRICE]}
                            onChange={(value) =>
                                setState(entityStates.PRICE, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Team ID (Integer)">
                        <TextInput
                            type="int"
                            value={state[entityStates.TEAM_ID]}
                            onChange={(value) =>
                                setState(entityStates.TEAM_ID, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <FormFieldTitle title="Entity Skills">
                    <Drawer
                        text={
                            state[entityStates.SKILL_IDS].length > 0
                                ? `Selected ${
                                      state[entityStates.SKILL_IDS].length
                                  } skill(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingSkills}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.SKILLS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={state[entityStates.SKILL_IDS]}
                                onSelect={(value) =>
                                    setState(entityStates.SKILL_IDS, value)
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <FormFieldTitle title="Entity Modifiers">
                    <Drawer
                        text={
                            state[entityStates.MODIFIER_IDS].length > 0
                                ? `Selected ${
                                      state[entityStates.MODIFIER_IDS].length
                                  } modifier(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingModifiers}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.MODIFIERS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={state[entityStates.MODIFIER_IDS]}
                                onSelect={(value) =>
                                    setState(entityStates.MODIFIER_IDS, value)
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Base Attack</h3>
                <FormFieldTitle title="Type">
                    <Dropdown
                        value={state[entityStates.BASE_ATTACK_TYPE]}
                        onChange={(value) =>
                            setState(entityStates.BASE_ATTACK_TYPE, value)
                        }
                    >
                        {Object.entries(EnumerationConstants.AttackTypes).map(
                            ([type, title]) => (
                                <option key={type} value={type}>
                                    {title}
                                </option>
                            )
                        )}
                    </Dropdown>
                </FormFieldTitle>
                <div className="page_row bottom">
                    <FormFieldTitle title="Damage (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.BASE_ATTACK_DAMAGE]}
                            onChange={(value) =>
                                setState(entityStates.BASE_ATTACK_DAMAGE, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Range (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.BASE_ATTACK_RANGE]}
                            onChange={(value) =>
                                setState(entityStates.BASE_ATTACK_RANGE, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Speed (Float)">
                        <TextInput
                            type="float"
                            value={state[entityStates.BASE_ATTACK_SPEED]}
                            onChange={(value) =>
                                setState(entityStates.BASE_ATTACK_SPEED, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Projectile Speed (Float)">
                        <TextInput
                            type="float"
                            value={
                                state[entityStates.BASE_ATTACK_PROJECTILE_SPEED]
                            }
                            onChange={(value) =>
                                setState(
                                    entityStates.BASE_ATTACK_PROJECTILE_SPEED,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <FormFieldTitle title="Damage Type">
                    <Drawer
                        text={
                            baseAttackDamageTypeId
                                ? `Selected damage type ID: ${baseAttackDamageTypeId}`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingTypeClasses}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.TYPE_CLASSES].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectionLimit={1}
                                selectedItems={
                                    baseAttackDamageTypeId
                                        ? [baseAttackDamageTypeId]
                                        : []
                                }
                                onSelect={(value) =>
                                    setState(
                                        entityStates.BASE_ATTACK_DAMAGE_TYPE_ID,
                                        value.length > 0 ? value[0] : undefined
                                    )
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Armour</h3>
                <FormFieldTitle title="Armour (Float)">
                    <TextInput
                        type="float"
                        value={state[entityStates.ARMOUR]}
                        onChange={(value) =>
                            setState(entityStates.ARMOUR, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Armour Type">
                    <Drawer
                        text={
                            armourTypeId
                                ? `Selected Armour Type ID: ${armourTypeId}`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingTypeClasses}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.TYPE_CLASSES].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectionLimit={1}
                                selectedItems={
                                    armourTypeId ? [armourTypeId] : []
                                }
                                onSelect={(value) =>
                                    setState(
                                        entityStates.ARMOUR_TYPE_ID,
                                        value.length > 0 ? value[0] : undefined
                                    )
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Assets</h3>
                <FormAssetFields
                    loading={props.loadingAssets}
                    selectedAssetsStateKey={entityStates.GROUPED_ASSET_IDS}
                />
                <div className="page_row right wrap">
                    <button className="solid_button" onClick={props.copyEntity}>
                        Copy as JSON
                    </button>
                    <button
                        className="solid_button blue"
                        onClick={props.createEntity}
                    >
                        Create Entity
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EntityForm;
