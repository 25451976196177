import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { FaBars, FaUserAlt } from 'react-icons/fa';
import { FaGear, FaRightFromBracket } from 'react-icons/fa6';

import LocalStorage from '../../../constants/LocalStorageConstants';
import useWindowSize from '../../../providers/WindowProvider';
import ConfirmationBox from '../confirmation_box/ConfirmationBox';
import Divider from '../divider/Divider';

import './NavBar.css';

function SideBarProfile(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    return (
        <div className="sidebar_box">
            <div className="sidebar_profile_picture">
                {loading && (
                    <div className="sidebar_profile_picture_layer">
                        <FaUserAlt size="50%" color="lightgray" />
                    </div>
                )}
                <img
                    className={loading ? 'hidden' : undefined}
                    alt="profilePicture"
                    onLoad={() => {
                        setLoading(false);
                    }}
                />
            </div>
            <div className="sidebar_box_text">
                <p className="user">{localStorage[LocalStorage.USER_NAME]}</p>
                <p className="role">{`(${
                    localStorage[LocalStorage.USER_ROLE]
                })`}</p>
            </div>
            <div className="sidebar_box_button_bar">
                <button
                    className="hollow_button sidebar_icon logout"
                    onClick={() => props.onLogOut(true)}
                >
                    <FaRightFromBracket />
                </button>
                <button
                    className="hollow_button sidebar_icon settings"
                    disabled={location.pathname === '/settings'}
                    onClick={() => navigate('/settings')}
                >
                    <FaGear />
                </button>
            </div>
        </div>
    );
}

function SideBarButton(props) {
    return (
        <button
            className="hollow_button sidebar_button"
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}

function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [logOutVisible, setLogOutVisible] = useState(false);

    useEffect(() => {
        setLogOutVisible(false);
    }, [location.pathname]);

    return (
        <div className="sidebar">
            <div className="sidebar_section">
                <SideBarProfile onLogOut={setLogOutVisible} />
                {logOutVisible && (
                    <ConfirmationBox
                        text="Are you sure you want to log out?"
                        onAccept={() => {
                            localStorage.clear();
                            navigate('/login');
                        }}
                        onReject={() => {
                            setLogOutVisible(false);
                        }}
                    />
                )}
            </div>
            <Divider horizontal />
            <div className="sidebar_section">
                <SideBarButton
                    disabled={location.pathname === '/'}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Master
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/assets'}
                    onClick={() => {
                        navigate('/assets');
                    }}
                >
                    Assets
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/skins'}
                    onClick={() => {
                        navigate('/skins');
                    }}
                >
                    Skins
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/type-classes'}
                    onClick={() => {
                        navigate('/type-classes');
                    }}
                >
                    Type Classes
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/skills'}
                    onClick={() => {
                        navigate('/skills');
                    }}
                >
                    Skills
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/modifiers'}
                    onClick={() => {
                        navigate('/modifiers');
                    }}
                >
                    Modifiers
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/entities'}
                    onClick={() => {
                        navigate('/entities');
                    }}
                >
                    Entities
                </SideBarButton>
                <SideBarButton
                    disabled={location.pathname === '/levels'}
                    onClick={() => {
                        navigate('/levels');
                    }}
                >
                    Levels
                </SideBarButton>
            </div>
        </div>
    );
}

function Drawer(props) {
    return (
        <>
            <div
                className={
                    props.isOpen ? 'navbar_drawer' : 'navbar_drawer hidden'
                }
            >
                {props.children}
            </div>
            <div
                className={
                    props.isOpen
                        ? 'navbar_drawer_bg'
                        : 'navbar_drawer_bg hidden'
                }
                onClick={props.onClick}
            ></div>
        </>
    );
}

function TopBar(props) {
    return (
        <div className="topbar">
            <button
                className="hollow_button drawer_open_button"
                onClick={props.onClick}
            >
                <FaBars />
            </button>
        </div>
    );
}

function NavBar() {
    const location = useLocation();
    const windowSize = useWindowSize();
    const isScreenBelowLimit = windowSize.width < 600;
    const [isScreenSmall, setIsScreenSmall] = useState(isScreenBelowLimit);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        setIsScreenSmall(isScreenBelowLimit);
    }, [isScreenBelowLimit]);

    useEffect(() => {
        setIsDrawerOpen(false);
    }, [location]);

    return isScreenSmall ? (
        <>
            <TopBar
                onClick={() => {
                    setIsDrawerOpen(true);
                }}
            />
            <Drawer
                isOpen={isDrawerOpen}
                onClick={() => {
                    setIsDrawerOpen(false);
                }}
            >
                <SideBar />
            </Drawer>
        </>
    ) : (
        <SideBar />
    );
}

export default NavBar;
