class ErrorUtils {
    static getErrorMessage(error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.error
        ) {
            return error.response.data.error;
        } else if (error.message) {
            return error.message;
        } else {
            return error;
        }
    }
}

export default ErrorUtils;
