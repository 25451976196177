class FileUtils {
    static convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () =>
                resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
            reader.onerror = (error) =>
                reject(`Base64 convertion error: ${error}`);
            reader.readAsDataURL(file);
        });
    }
}

export default FileUtils;
