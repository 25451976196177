// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_field_container {
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

.form_field_title {
    font-size: 0.8rem;
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-word;
    color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/form_field_title/FormFieldTitle.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".form_field_container {\n    height: fit-content;\n    width: 100%;\n}\n\n.form_field_title {\n    font-size: 0.8rem;\n    font-weight: bold;\n    word-wrap: break-word;\n    word-break: break-word;\n    color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
