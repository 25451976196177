import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import TypeClassService from '../../../services/TypeClassService';
import StateConstants from '../../../constants/StateConstants';
import TypeClassUtils from '../../../utils/TypeClassUtils';
import useWindowSize from '../../../providers/WindowProvider';
import TypeClassForm from './TypeClassForm';
import TypeClassList from './TypeClassList';
import StateContext from '../../../providers/StateProvider';
import AssetService from '../../../services/AssetService';
import ErrorUtils from '../../../utils/ErrorUtils';

function TypeClass() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const typeClassStates = StateConstants.TypeClassStates;
    const [loadingTypeClasses, setLoadingTypeClasses] = useState(true);
    const [loadingAssets, setLoadingAssets] = useState(true);

    // Functions
    const getTypeClasses = async () => {
        setLoadingTypeClasses(true);
        await TypeClassService.getAllTypeClasses()
            .then((response) => {
                setState(commonStates.TYPE_CLASSES, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingTypeClasses(false);
    };

    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setLoadingAssets(false);
    };

    const createTypeClass = async () => {
        const notificationId = raiseNotification('Creating type class...', {
            permanent: true,
        });
        const data = TypeClassUtils.createTypeClassDTO(
            state[typeClassStates.NAME],
            state[typeClassStates.DESC_KEY],
            Object.values(state[typeClassStates.GROUPED_ASSET_IDS]).flat()
        );
        await TypeClassService.createTypeClass(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Type class was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                getTypeClasses();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    // Startup
    useEffect(() => {
        getTypeClasses();
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <TypeClassForm
                createTypeClass={createTypeClass}
                loadingAssets={loadingAssets}
            />
            <TypeClassList loadingTypeClasses={loadingTypeClasses} />
        </div>
    );
}

export default TypeClass;
