import { useEffect, useRef, useState } from 'react';

import { FaChevronDown } from 'react-icons/fa';

import CommonConstants from '../../../constants/CommonConstants';
import Divider from '../divider/Divider';

import './Dropdown.css';

function Dropdown(props) {
    const selectRef = useRef();
    const [value, setValue] = useState(
        props.value ? props.value : props.defaultValue ? props.defaultValue : ''
    );
    const [text, setText] = useState(CommonConstants.NOT_SELECTED_TEXT);

    useEffect(() => {
        props.onChange(value);
        if (selectRef.current) {
            setText(selectRef.current.selectedOptions[0].text);
        }
        // eslint-disable-next-line
    }, [value, selectRef.current]);

    return (
        <div className="dropdown">
            <div
                className={
                    props.defaultValue === undefined && value === ''
                        ? 'dropdown_header'
                        : 'dropdown_header selected'
                }
            >
                {text}
            </div>
            <Divider />
            <div className="dropdown_icon">
                <FaChevronDown />
            </div>
            <select
                id={props.id}
                ref={selectRef}
                className="hidden_select"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            >
                {props.defaultValue === undefined && (
                    <option key="" value="" disabled hidden>
                        {CommonConstants.NOT_SELECTED_TEXT}
                    </option>
                )}
                {props.children}
            </select>
        </div>
    );
}

export default Dropdown;
