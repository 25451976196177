import EnumerationConstants from '../constants/EnumerationConstants';
import DataUtils from './DataUtils';

class EntityUtils {
    static createEntityDTO(
        entityName,
        entityDescriptionKey,
        entityPrice,
        entityTeamId,
        entitySizeScale,
        entityHealthPoints,
        entityMovementSpeed,
        entityBaseHPRegen,
        entityArmour,
        entityArmourTypeId,
        entityBAType,
        entityBARange,
        entityBASpeed,
        entityBAProjectileSpeed,
        entityBADamage,
        entityBADamageTypeId,
        entitySkillIds,
        entityModifierIds,
        entityAssetIds
    ) {
        return {
            name: DataUtils.setString(entityName),
            descriptionKey: DataUtils.setString(entityDescriptionKey),
            price: DataUtils.setFloat(entityPrice),
            teamId: DataUtils.setInt(entityTeamId),
            sizeScale: DataUtils.setFloat(entitySizeScale),
            healthPoints: DataUtils.setFloat(entityHealthPoints),
            movementSpeed: DataUtils.setFloat(entityMovementSpeed),
            baseHPRegen: DataUtils.setFloat(entityBaseHPRegen),
            armour: DataUtils.setFloat(entityArmour),
            armourTypeId: DataUtils.setInt(entityArmourTypeId),
            baseAttackType: DataUtils.setString(entityBAType),
            baseAttackRange: DataUtils.setFloat(entityBARange),
            baseAttackSpeed: DataUtils.setFloat(entityBASpeed),
            baseAttackProjectileSpeed: DataUtils.setFloat(
                entityBAProjectileSpeed
            ),
            baseAttackDamage: DataUtils.setFloat(entityBADamage),
            baseAttackDamageTypeId: DataUtils.setInt(entityBADamageTypeId),
            skillIds: entitySkillIds,
            modifierIds: entityModifierIds,
            assetIds: entityAssetIds,
        };
    }

    static createEntityLabelData(entity) {
        return [
            { title: 'ID', value: entity.id },
            { title: 'Entity Name', value: entity.name },
            { title: 'Desc. Key', value: entity.descriptionKey },
            { title: 'Health Points', value: entity.healthPoints },
            { title: 'Armour', value: entity.armour },
            { title: 'Movement Speed', value: entity.movementSpeed },
            { title: 'Price', value: entity.price },
            { title: 'Team ID', value: entity.teamId },
            { title: 'Created At', value: entity.createdAt },
        ];
    }

    static createArmourLabelData(entity) {
        return [
            { title: 'Type Class ID', value: entity.armourType.id },
            { title: 'Type Class Name', value: entity.armourType.name },
            { title: 'Armour Value', value: entity.armour },
        ];
    }

    static createBaseAttackLabelData(entity) {
        return [
            {
                title: 'Type',
                value: EnumerationConstants.AttackTypes[entity.baseAttackType],
            },
            { title: 'Damage Type ID', value: entity.baseAttackDamageType.id },
            { title: 'Damage Type', value: entity.baseAttackDamageType.name },
            { title: 'Damage', value: entity.baseAttackDamage },
            { title: 'Range', value: entity.baseAttackRange },
            { title: 'Speed', value: entity.baseAttackSpeed },
            {
                title: 'Projectile Speed',
                value: entity.baseAttackProjectileSpeed,
            },
        ];
    }
}

export default EntityUtils;
