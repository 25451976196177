import './LabelPill.css';

function LabelPill(props) {
    const circleColors = {
        C: 'yellow',
        I: 'blue',
        VE: 'green',
        TE: 'red',
        CE: 'pink',
        DE: 'purple',
    };

    const setCircleClassName = () => {
        let className = 'label_pill';
        if (props.onClick) {
            className += ' clickable';
        }
        if (props.label && props.label in circleColors) {
            className += ` ${circleColors[props.label]}`;
        } else if (!props.label) {
            className += ' lightgray';
        }
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    };

    return (
        <div
            className={setCircleClassName()}
            onClick={props.onClick}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
        >
            {props.label}
        </div>
    );
}

export default LabelPill;
