import './FormFieldTitle.css';

function FormFieldTitle(props) {
    return (
        <div className="form_field_container">
            {props.title && <p className="form_field_title">{props.title}</p>}
            {props.children}
        </div>
    );
}

export default FormFieldTitle;
