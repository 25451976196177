// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
    width: 100%;
    border-radius: 8px;
    border: 2px solid lightgray;
}

.drawer_header {
    color: gray;
    display: flex;
    height: 42px;
    width: auto;
}

.drawer_header_label {
    flex: 1 1;
    color: gray;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
}

.drawer_header_icon {
    color: lightgray;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
}

.drawer_content {
    border-top: 2px solid lightgray;
    width: 100%;
    min-height: 50px;
    overflow: hidden;
}

.drawer_content.hidden {
    border: unset;
    min-height: 0;
    height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/drawer/Drawer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,SAAO;IACP,WAAW;IACX,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,WAAW;IACX,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;AACb","sourcesContent":[".drawer {\n    width: 100%;\n    border-radius: 8px;\n    border: 2px solid lightgray;\n}\n\n.drawer_header {\n    color: gray;\n    display: flex;\n    height: 42px;\n    width: auto;\n}\n\n.drawer_header_label {\n    flex: 1;\n    color: gray;\n    font-size: 1rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 10px 15px;\n}\n\n.drawer_header_icon {\n    color: lightgray;\n    font-size: 1rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 15px;\n}\n\n.drawer_content {\n    border-top: 2px solid lightgray;\n    width: 100%;\n    min-height: 50px;\n    overflow: hidden;\n}\n\n.drawer_content.hidden {\n    border: unset;\n    min-height: 0;\n    height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
