import { useContext, useEffect, useState } from 'react';

import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import LevelUtils from '../../../utils/LevelUtils';
import TextInput from '../../common/text_input/TextInput';
import Drawer from '../../common/drawer/Drawer';
import Table from '../../common/table/Table';
import Modal from '../../common/modal/Modal';

function WaveFormModal(props) {
    // States
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [waveName, setWaveName] = useState('');
    const [waveDescriptionKey, setWaveDescriptionKey] = useState('');
    const [wavePriority, setWavePriority] = useState('');
    const [waveDuration, setWaveDuration] = useState('');
    const [waveModifierIds, setWaveModifierIds] = useState([]);
    const [waveEntityIds, setWaveEntityIds] = useState([]);

    // Functions
    useEffect(() => {
        if (props.wave) {
            setWaveName(props.wave.name);
            setWaveDescriptionKey(props.wave.descriptionKey);
            setWavePriority(props.wave.priority);
            setWaveDuration(props.wave.duration);
            setWaveModifierIds(props.wave.modifierIds);
            setWaveEntityIds(props.wave.entityIds);
        } else {
            setWaveName('');
            setWaveDescriptionKey('');
            setWavePriority('');
            setWaveDuration('');
            setWaveModifierIds([]);
            setWaveEntityIds([]);
        }
    }, [props.wave]);

    const addWave = () => {
        const wave = LevelUtils.createWaveDTO(
            waveName,
            waveDescriptionKey,
            wavePriority,
            waveDuration,
            waveModifierIds,
            waveEntityIds
        );
        props.createWave(wave);
    };

    return (
        <Modal visible={props.visible} onClose={props.onClose}>
            <div className="page_grid modal_fixed_width">
                <h2>Add Wave</h2>
                <FormFieldTitle title="Wave Name (String)">
                    <TextInput value={waveName} onChange={setWaveName} />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={waveDescriptionKey}
                        onChange={setWaveDescriptionKey}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Priority (Integer)">
                    <TextInput
                        type="int"
                        value={wavePriority}
                        onChange={setWavePriority}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Duration (Float)">
                    <TextInput
                        type="float"
                        value={waveDuration}
                        onChange={setWaveDuration}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Wave Modifiers">
                    <Drawer
                        text={
                            waveModifierIds.length > 0
                                ? `Selected ${waveModifierIds.length} modifier(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingModifiers}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.MODIFIERS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={waveModifierIds}
                                onSelect={setWaveModifierIds}
                                multiInstanceSelection
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <FormFieldTitle title="Wave Entities">
                    <Drawer
                        text={
                            waveEntityIds.length > 0
                                ? `Selected ${waveEntityIds.length} entities.`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingEntities}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.ENTITIES].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={waveEntityIds}
                                onSelect={setWaveEntityIds}
                                multiInstanceSelection
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <div className="page_row right wrap">
                    <button className="solid_button blue" onClick={addWave}>
                        Add Wave
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default WaveFormModal;
