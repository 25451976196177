import { useState } from 'react';

import { FaCircleXmark } from 'react-icons/fa6';

import ConfirmationModal from '../../../common/confirmation_modal/ConfirmationModal';
import FormFieldTitle from '../../../common/form_field_title/FormFieldTitle';
import AssetUtils from '../../../../utils/AssetUtils';
import TextBox from '../../../common/text_box/TextBox';
import Modal from '../../../common/modal/Modal';

import './AssetDetailModal.css';

function AssetImage(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <div
            className={`asset_image_container ${
                loading || error ? 'empty' : ''
            }`.trim()}
        >
            {error ? (
                <FaCircleXmark className="asset_image_icon" />
            ) : (
                <img
                    className={
                        loading && !error ? 'asset_image hidden' : 'asset_image'
                    }
                    alt={props.alt}
                    src={props.src}
                    onLoad={() => {
                        setLoading(false);
                    }}
                    onError={() => {
                        setError(true);
                    }}
                />
            )}
        </div>
    );
}

function AssetDetailModal(props) {
    const [confirmVisible, setConfirmVisible] = useState(false);

    return (
        <>
            <Modal title="Asset Details" visible={props.asset} draggable>
                <div className="page_grid modal_fixed_width">
                    <div className="page_row">
                        <AssetImage
                            alt={props.asset && props.asset.assetReference}
                            src={props.asset && props.asset.imageUrl}
                        />
                    </div>
                    <div className="asset_detail_data_container">
                        {props.asset &&
                            AssetUtils.createImageData(props.asset).map(
                                (item, index) => (
                                    <FormFieldTitle
                                        key={index}
                                        title={item.title}
                                    >
                                        <TextBox wrap>{item.value}</TextBox>
                                    </FormFieldTitle>
                                )
                            )}
                    </div>
                    <div className="page_row right wrap">
                        <button
                            className="solid_button red"
                            onClick={() =>
                                props.asset &&
                                props.deleteAsset &&
                                setConfirmVisible(true)
                            }
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>
            <ConfirmationModal
                visible={confirmVisible}
                title={`Confirm Delete (Asset ID: ${
                    props.asset && props.asset.id
                })`}
                text={`Are you sure you want to delete the asset?`}
                onClose={() => setConfirmVisible(false)}
                onDecline={() => setConfirmVisible(false)}
                onConfirm={() => {
                    setConfirmVisible(false);
                    props.asset && props.deleteAsset(props.asset.id);
                }}
            />
        </>
    );
}

export default AssetDetailModal;
