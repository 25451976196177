import { useEffect, useState } from 'react';

import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

import './TextInput.css';

function TextInput(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        setCurrentValue(props.value ? props.value : '');
        props.onChange(props.value ? props.value : '');
        // eslint-disable-next-line
    }, [props.value]);

    const isInputValid = (type, input) => {
        if (type === 'int') {
            const inputPattern = /^[0-9-]*$/i;
            return inputPattern.test(input);
        } else if (type === 'float') {
            const inputPattern = /^[0-9.-]*$/i;
            return inputPattern.test(input);
        } else {
            return true;
        }
    };

    const handleChange = (e) => {
        if (isInputValid(props.type, e.target.value)) {
            setCurrentValue(e.target.value);
            props.onChange(e.target.value);
        } else {
            e.target.value = currentValue;
        }
    };

    return (
        <div className="text_input_outline">
            <input
                id={props.id}
                className="text_input"
                type={props.password && !showPassword ? 'password' : undefined}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleChange}
            />
            {props.password &&
                (showPassword ? (
                    <RiEyeLine
                        size={25}
                        className="password_toggle_icon"
                        onClick={() => setShowPassword(false)}
                    />
                ) : (
                    <RiEyeOffLine
                        size={25}
                        className="password_toggle_icon"
                        onClick={() => setShowPassword(true)}
                    />
                ))}
        </div>
    );
}

export default TextInput;
