import axios from 'axios';

import AuthService from './AuthService';

class MasterService {
    static async createMaster() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/masters', null);
        return response;
    }

    static async selectLatestMaster(masterId) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post(`/masters/select/${masterId}`, null);
        return response;
    }

    static async getAllMasterReferences() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/masters/references');
        return response;
    }

    static async getLatestMaster() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/masters/latest');
        return response;
    }

    static async getMaster(masterId) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get(`/masters/${masterId}`);
        return response;
    }

    static async deleteMaster(masterId) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.delete(`/masters/${masterId}`);
        return response;
    }
}

export default MasterService;
