import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import ClipboardUtils from '../../../utils/ClipboardUtils';
import MasterService from '../../../services/MasterService';
import ErrorUtils from '../../../utils/ErrorUtils';
import LabelPill from '../../common/label_pill/LabelPill';
import Divider from '../../common/divider/Divider';
import Table from '../../common/table/Table';

function MasterList(props) {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Data
    const [selectedMasters, setSelectedMasters] = useState([]);

    // Functions
    useEffect(() => {
        setSelectedMasters(props.selected ? [props.selected.id] : []);
    }, [props.selected]);

    const selectMaster = (masterIds) => {
        setSelectedMasters(masterIds);
        if (masterIds.length > 0) {
            props.selectMaster(masterIds[0]);
        }
    };

    const copyMasterUrl = async (url) => {
        try {
            await ClipboardUtils.copyToClipboard(url);
            raiseNotification('Copied to clipboard.', {
                type: 'success',
            });
        } catch (error) {
            raiseNotification(ErrorUtils.getErrorMessage(error), {
                type: 'error',
            });
        }
    };

    const setMasterAsLatest = async (masterId) => {
        const notificationId = raiseNotification(
            'Selecting master as latest...',
            {
                permanent: true,
            }
        );
        await MasterService.selectLatestMaster(masterId)
            .then(() => {
                removeNotification(notificationId);
                raiseNotification(
                    `Master was selected as latest: ${masterId}`,
                    { type: 'success' }
                );
                props.getMasters();
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const deleteMaster = async (masterId) => {
        const notificationId = raiseNotification(`Deleting master...`, {
            permanent: true,
        });
        await MasterService.deleteMaster(masterId)
            .then(() => {
                removeNotification(notificationId);
                raiseNotification('Master was deleted successfully.', {
                    type: 'success',
                });
                props.getMasters();
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    return (
        <div className="page_section limit_width">
            <div className="page_grid">
                <h2>Master List</h2>
                <Table
                    key="id"
                    loading={props.loading}
                    columns={[
                        { header: 'ID', field: 'shownId' },
                        { header: 'Version', field: 'version' },
                        { header: 'URL', field: 'url', resize: false },
                        { header: 'Created At', field: 'createdAt' },
                        { header: 'Action', field: 'action', resize: false },
                    ]}
                    selectionKey="id"
                    selectionLimit={1}
                    acceptUnselect={false}
                    selectedItems={selectedMasters}
                    onSelect={selectMaster}
                    data={props.masters.map((item) => ({
                        id: item.masterId,
                        shownId: (
                            <>
                                {item.selected ? (
                                    <LabelPill
                                        className="blue"
                                        label="latest"
                                    />
                                ) : undefined}
                                {item.masterId}
                            </>
                        ),
                        version: item.version,
                        url: (
                            <button
                                className="hollow_button table_button blue copy"
                                onClick={() => copyMasterUrl(item.url)}
                            >
                                Copy
                            </button>
                        ),
                        createdAt: item.createdAt,
                        action: (
                            <>
                                <button
                                    className="hollow_button table_button blue copy"
                                    onClick={() =>
                                        setMasterAsLatest(item.masterId)
                                    }
                                >
                                    Select As Latest
                                </button>
                                <Divider className="table_divider" />
                                <button
                                    className="hollow_button table_button red delete"
                                    onClick={() => deleteMaster(item.masterId)}
                                >
                                    Delete
                                </button>
                            </>
                        ),
                    }))}
                />
            </div>
        </div>
    );
}

export default MasterList;
