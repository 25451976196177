import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import useWindowSize from '../../../providers/WindowProvider';
import LocalStorage from '../../../constants/LocalStorageConstants';
import SettingsList from './SettingsList';
import UserService from '../../../services/UserService';
import ErrorUtils from '../../../utils/ErrorUtils';

import './Settings.css';

function Settings() {
    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // Data
    const [user, setUser] = useState({});
    const [loadingUser, setLoadingUser] = useState(true);

    const getData = async () => {
        setLoadingUser(true);
        UserService.getUserById(localStorage[LocalStorage.USER_ID])
            .then((response) => {
                setUser(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            })
            .finally(() => setLoadingUser(false));
    };

    // Startup
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <SettingsList user={user} loading={loadingUser} />
        </div>
    );
}

export default Settings;
