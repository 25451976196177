import { useContext, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import StateConstants from '../../../constants/StateConstants';
import ModifierUtils from '../../../utils/ModifierUtils';
import StateContext from '../../../providers/StateProvider';
import EntityUtils from '../../../utils/EntityUtils';
import AssetUtils from '../../../utils/AssetUtils';
import SkillUtils from '../../../utils/SkillUtils';
import LabelPill from '../../common/label_pill/LabelPill';
import Tooltip from '../../common/tooltip/Tooltip';
import Table from '../../common/table/Table';

function AssetLabels(props) {
    return (
        <>
            {props.assets.map((asset, index) => (
                <LabelPill
                    key={index}
                    label={
                        EnumerationConstants.AssetTypes[asset.assetType].label
                    }
                    onMouseOver={() =>
                        props.setTooltip(AssetUtils.createImageData(asset))
                    }
                    onMouseOut={() => props.setTooltip(undefined)}
                />
            ))}
        </>
    );
}

function SkillLabels(props) {
    return (
        <>
            {props.skills.map((skill, index) => (
                <LabelPill
                    key={index}
                    label={String(skill.id)}
                    onMouseOver={() =>
                        props.setTooltip(SkillUtils.createSkillLabelData(skill))
                    }
                    onMouseOut={() => props.setTooltip(undefined)}
                />
            ))}
        </>
    );
}

function ModifierLabels(props) {
    return (
        <>
            {props.modifiers.map((modifier, index) => (
                <LabelPill
                    key={index}
                    label={String(modifier.id)}
                    onMouseOver={() =>
                        props.setTooltip(
                            ModifierUtils.createModifierLabelData(modifier)
                        )
                    }
                    onMouseOut={() => props.setTooltip(undefined)}
                />
            ))}
        </>
    );
}

const EntityList = (props) => {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [tooltipContent, setTooltipContent] = useState(undefined);

    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Entity List</h2>
                <Tooltip
                    show={Boolean(tooltipContent)}
                    content={tooltipContent}
                />
                <Table
                    key="id"
                    loading={props.loadingEntities}
                    columns={[
                        { header: 'ID', field: 'id' },
                        { header: 'Name', field: 'name' },
                        { header: 'Desc. Key', field: 'descKey' },
                        { header: 'Health Points', field: 'healthPoints' },
                        { header: 'Base HP Regen.', field: 'baseHPRegen' },
                        { header: 'Siz Scale', field: 'sizeScale' },
                        { header: 'Movement Speed', field: 'movementSpeed' },
                        { header: 'Price', field: 'price' },
                        { header: 'Team ID', field: 'teamId' },
                        { header: 'Armour', field: 'armour' },
                        { header: 'Base Attack', field: 'baseAttack' },
                        { header: 'Assets', field: 'assets' },
                        { header: 'Skills', field: 'skills' },
                        { header: 'Modifiers', field: 'modifiers' },
                        { header: 'Created At', field: 'createdAt' },
                    ]}
                    data={state[commonStates.ENTITIES].map((item) => ({
                        id: item.id,
                        name: item.name,
                        descKey: item.descriptionKey,
                        healthPoints: item.healthPoints,
                        baseHPRegen: item.baseHPRegen,
                        sizeScale: item.sizeScale,
                        movementSpeed: item.movementSpeed,
                        price: item.price,
                        teamId: item.teamId,
                        armour: (
                            <LabelPill
                                label={item.armourType.name}
                                onMouseOver={() =>
                                    setTooltipContent(
                                        EntityUtils.createArmourLabelData(item)
                                    )
                                }
                                onMouseOut={() => setTooltipContent(undefined)}
                            />
                        ),
                        baseAttack: (
                            <LabelPill
                                label={
                                    EnumerationConstants.AttackTypes[
                                        item.baseAttackType
                                    ]
                                }
                                onMouseOver={() =>
                                    setTooltipContent(
                                        EntityUtils.createBaseAttackLabelData(
                                            item
                                        )
                                    )
                                }
                                onMouseOut={() => setTooltipContent(undefined)}
                            />
                        ),
                        assets: (
                            <AssetLabels
                                assets={item.assets}
                                setTooltip={setTooltipContent}
                            />
                        ),
                        skills: (
                            <SkillLabels
                                skills={item.skills}
                                setTooltip={setTooltipContent}
                            />
                        ),
                        modifiers: (
                            <ModifierLabels
                                modifiers={item.modifiers}
                                setTooltip={setTooltipContent}
                            />
                        ),
                        createdAt: item.createdAt,
                    }))}
                />
            </div>
        </div>
    );
};

export default EntityList;
