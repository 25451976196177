import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import useWindowSize from './providers/WindowProvider';
import LocalStorage from './constants/LocalStorageConstants';
import AuthService from './services/AuthService';
import AxiosUtils from './utils/AxiosUtils';
import Loading from './components/pages/loading_page/Loading';
import NavBar from './components/common/navbar/NavBar';
import Login from './components/pages/login_page/Login';
import Settings from './components/pages/settings_page/Settings';
import Master from './components/pages/master_page/Master';
import Asset from './components/pages/asset_page/Asset';
import Entity from './components/pages/entity_page/Entity';
import Skill from './components/pages/skill_page/Skill';
import Modifier from './components/pages/modifier_page/Modifier';
import Skin from './components/pages/skin_page/Skin';
import TypeClass from './components/pages/type_class_page/TypeClass';
import Level from './components/pages/level_page/Level';

import './App.css';

function App() {
    // Navigation
    const navigate = useNavigate();
    const location = useLocation();

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 600;
    const [vertical, setVertical] = useState(isScreenSmall);
    useEffect(() => setVertical(isScreenSmall), [isScreenSmall]);

    // Data
    const [authChecked, setAuthChecked] = useState(false);

    // Functions
    const checkAuth = async () => {
        setAuthChecked(false);
        if (
            !localStorage[LocalStorage.USER_EMAIL] ||
            !localStorage[LocalStorage.USER_ID] ||
            !localStorage[LocalStorage.USER_NAME] ||
            !localStorage[LocalStorage.ACCESS_TOKEN] ||
            !localStorage[LocalStorage.REFRESH_TOKEN]
        ) {
            localStorage.clear();
            if (location.pathname !== '/login') {
                navigate('/login');
            }
            setAuthChecked(true);
        } else {
            AuthService.refreshAccessTokenIfExpired()
                .then(() => {
                    if (location.pathname === '/login') {
                        navigate('/');
                    }
                })
                .catch(() => {
                    localStorage.clear();
                    if (location.pathname !== '/login') {
                        navigate('/login');
                    }
                })
                .finally(() => {
                    setAuthChecked(true);
                });
        }
    };

    // Startup
    useEffect(() => {
        AxiosUtils.setDefaultSettings();
        AxiosUtils.setAuthHeader();
        checkAuth();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={vertical ? 'app vertical' : 'app'}>
            {authChecked ? (
                <>
                    {location.pathname !== '/login' ? <NavBar /> : undefined}
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<Master />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/entities" element={<Entity />} />
                        <Route path="/skills" element={<Skill />} />
                        <Route path="/modifiers" element={<Modifier />} />
                        <Route path="/skins" element={<Skin />} />
                        <Route path="/assets" element={<Asset />} />
                        <Route path="/type-classes" element={<TypeClass />} />
                        <Route path="/levels" element={<Level />} />
                    </Routes>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default App;
