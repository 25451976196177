class LocalStorage {
    static USER_ID = 'userId';
    static USER_EMAIL = 'userEmail';
    static USER_NAME = 'userName';
    static USER_ROLE = 'userRole';
    static ACCESS_TOKEN = 'accessToken';
    static REFRESH_TOKEN = 'refreshToken';
}

export default LocalStorage;
