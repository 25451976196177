class ClipboardUtils {
    static async copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
        } catch (error) {
            throw new Error('Unable to copy to clipboard.');
        }
    }
}

export default ClipboardUtils;
