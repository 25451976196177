// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
    background-color: lightgray;
}

.divider.horizontal {
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    width: 100%;
}

.divider.vertical {
    width: 2px;
    min-width: 2px;
    max-width: 2px;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/divider/Divider.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;IACd,cAAc;IACd,YAAY;AAChB","sourcesContent":[".divider {\n    background-color: lightgray;\n}\n\n.divider.horizontal {\n    height: 2px;\n    min-height: 2px;\n    max-height: 2px;\n    width: 100%;\n}\n\n.divider.vertical {\n    width: 2px;\n    min-width: 2px;\n    max-width: 2px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
