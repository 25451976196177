import axios from 'axios';

import AuthService from './AuthService';

class EntityService {
    static async createEntity(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/entities', data);
        return response;
    }

    static async getAllEntities() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/entities');
        return response;
    }
}

export default EntityService;
