import { useState } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import Divider from '../divider/Divider';

import './Drawer.css';

function Drawer(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div id={props.id} className="drawer">
            <div
                className="drawer_header"
                onClick={() => {
                    setIsOpen((prevState) => !prevState);
                }}
            >
                <div className="drawer_header_label">{props.text}</div>
                <Divider />
                <div className="drawer_header_icon">
                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>
            <div
                className={isOpen ? 'drawer_content' : 'drawer_content hidden'}
            >
                {props.children}
            </div>
        </div>
    );
}

export default Drawer;
