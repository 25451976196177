import Modal from '../modal/Modal';

function ConfirmationModal(props) {
    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="page_grid modal_fixed_width">
                <p>{props.text}</p>
                <div className="page_row right wrap">
                    <button
                        className="solid_button blue"
                        onClick={props.onDecline}
                    >
                        {props.declineText ? props.declineText : 'No'}
                    </button>
                    <button
                        className="solid_button red"
                        onClick={props.onConfirm}
                    >
                        {props.confirmText ? props.confirmText : 'Yes'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;
