import { useRef } from 'react';

import { Editor } from '@monaco-editor/react';
import { FaSpinner } from 'react-icons/fa';

import './MonacoEditor.css';

function MonacoEditorLoading() {
    return (
        <div className="loading">
            <FaSpinner className="loading_icon" />
            <p className="loading_text">Loading...</p>
        </div>
    );
}

function MonacoEditor(props) {
    const editorRef = useRef(null);
    const monacoRef = useRef(null);

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        monacoRef.current = monaco;
    }

    return props.loading ? (
        <div className="monaco_editor loading" style={{ height: props.height }}>
            <MonacoEditorLoading />
        </div>
    ) : (
        <Editor
            className="monaco_editor"
            loading={<MonacoEditorLoading />}
            height={props.height}
            language={props.language}
            value={props.value}
            onChange={props.onChange}
            options={{
                readOnly: props.readOnly,
                automaticLayout: true,
                scrollBeyondLastLine: false,
            }}
            onMount={handleEditorDidMount}
        />
    );
}

export default MonacoEditor;
