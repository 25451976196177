class StateConstants {
    static CommonStates = {
        ASSETS: 'assets',
        TYPE_CLASSES: 'typeClasses',
        SKILLS: 'skills',
        MODIFIERS: 'modifiers',
        ENTITIES: 'entities',
        LEVELS: 'levels',
    };

    static AssetStates = {
        TYPE: 'assetFormType',
        REFERENCE: 'assetFormReference',
        IMAGE: 'assetFormImage',
    };

    static TypeClassStates = {
        NAME: 'typeClassFormName',
        DESC_KEY: 'typeClassFormDescriptionKey',
        GROUPED_ASSET_IDS: 'typeClassFormGroupedAssetIds',
    };

    static SkillStates = {
        NAME: 'skillFormName',
        DESC_KEY: 'skillFormDescriptionKey',
        PROJ_SPEED: 'skillFormProjectileSpeed',
        RANGE: 'skillFormRange',
        PRICE: 'skillFormPrice',
        MODIFIER_IDS: 'skillFormModifierIds',
        ATTACK_SPEED: 'skillFormAttackSpeed',
        ATTACK_TYPE: 'skillFormAttackType',
        DAMAGE: 'skillFormDamage',
        DAMAGE_TYPE_ID: 'skillFormDamageTypeId',
        GROUPED_ASSET_IDS: 'skillFormGroupedAssetIds',
    };

    static ModifierStates = {
        NAME: 'modifierFormName',
        DESC_KEY: 'modifierFormDescriptionKey',
        TYPE: 'modifierFormProjectileSpeed',
        EXPIRATION: 'modifierFormRange',
        VALUE: 'modifierFormPrice',
        PRICE: 'modifierFormModifierIds',
        GROUPED_ASSET_IDS: 'modifierFormGroupedAssetIds',
    };

    static EntityStates = {
        NAME: 'entityFormName',
        DESC_KEY: 'entityFormDescriptionKey',
        PRICE: 'entityFormPrice',
        TEAM_ID: 'entityFormTeamId',
        SIZE_SCALE: 'entityFormSizeScale',
        HEALTH_POINTS: 'entityFormHealthPoints',
        MOVEMENT_SPEED: 'entityFormMovementSpeed',
        BASE_HP_REGEN: 'entityFormBaseHPRegen',
        ARMOUR: 'entityFormArmour',
        ARMOUR_TYPE_ID: 'entityFormArmourTypeId',
        BASE_ATTACK_TYPE: 'entityFormBaseAttackType',
        BASE_ATTACK_RANGE: 'entityFormBaseAttackRange',
        BASE_ATTACK_SPEED: 'entityFormBaseAttackSpeed',
        BASE_ATTACK_PROJECTILE_SPEED: 'entityFormBaseAttackProjectileSpeed',
        BASE_ATTACK_DAMAGE: 'entityFormBaseAttackDamage',
        BASE_ATTACK_DAMAGE_TYPE_ID: 'entityFormBaseAttackDamageTypeId',
        SKILL_IDS: 'entityFormSkillIds',
        MODIFIER_IDS: 'entityFormModifierIds',
        GROUPED_ASSET_IDS: 'entityFormGroupedAssetIds',
    };

    static LevelStates = {
        NAME: 'levelFormName',
        DESC_KEY: 'levelFormDescriptionKey',
        GAME_MODE: 'levelFormGameMode',
        PRIORITY: 'levelFormPriority',
        STARTING_GOLD: 'levelFormStartingGold',
        INITIAL_PLAYER_INCOME: 'levelFormInitialPlayerIncome',
        INITIAL_HP: 'levelFormInitialHP',
        INITIAL_HP_REGEN: 'levelFormInitialHPRegen',
        INCOME_INCREASE_PER_SECOND: 'levelFormIncomeIncreasePerSecond',
        INCOME_INCREASE_AMOUNT: 'levelFormIncomeIncreaseAmount',
        WAVES: 'levelFormWaves',
        SKILL_POOL: 'levelFormSkillPool',
        MODIFIER_IDS: 'levelFormModifierIds',
        MODIFIER_POOL: 'levelFormModifierPool',
        GROUPED_SHOWCASE_ASSET_IDS: 'levelFormGroupedShowcaseAssetIds',
    };

    static ShopStates = {
        RESET_TIME_IN_SECONDS: 'levelFormShopResetTimeInSeconds',
        GOLD_INCREASE_PER_REROLL: 'levelFormShopGoldIncreasePerReRoll',
        INITIAL_REROLL_COUNT: 'levelFormShopInitialReRollCount',
        INITIAL_REROLL_COST: 'levelFormShopInitialReRollCost',
        INITIAL_FREE_REROLLS: 'levelFormShopInitialFreeReRolls',
        REROLL_COUNT_INCREASE_TIME_IN_SECONDS:
            'levelFormShopReRollCountIncreaseTimeInSeconds',
        REROLL_COUNT_INCREASE_AMOUNT: 'levelFormShopReRollCountIncreaseAmount',
        REROLL_COST_INCREASE_PER_REROLL:
            'levelFormShopReRollCostIncreasePerReRoll',
    };
}

export default StateConstants;
