import { useState } from 'react';

import { FaCheck, FaSpinner } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';

import DataUtils from '../../../utils/DataUtils';
import LabelPill from '../label_pill/LabelPill';
import Tooltip from '../tooltip/Tooltip';

import './Gallery.css';

function GalleryImage(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <div className="gallery_item_container">
            <div
                className="gallery_item"
                onClick={props.onClick}
                onMouseOver={props.onMouseOver}
                onMouseOut={props.onMouseOut}
            >
                {props.isSelected !== undefined && (
                    <div className="gallery_item_layer top_left">
                        <div className="gallery_item_select_icon">
                            {props.isSelected ? <FaCheck /> : undefined}
                        </div>
                    </div>
                )}
                {props.watermark && (
                    <div className="gallery_item_layer bottom_right">
                        <LabelPill label={props.watermark} />
                    </div>
                )}
                {props.isSelected && (
                    <div className="gallery_item_layer selected"></div>
                )}
                {error ? (
                    <div className="gallery_item_layer center">
                        <FaCircleXmark size="30%" color="lightgray" />
                    </div>
                ) : (
                    <img
                        className={
                            loading && !error
                                ? 'gallery_image hidden'
                                : 'gallery_image'
                        }
                        loading="lazy"
                        alt={props.alt}
                        src={props.src}
                        onLoad={() => {
                            setLoading(false);
                        }}
                        onError={() => {
                            setError(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

function Gallery(props) {
    const [tooltipContent, setTooltipContent] = useState(undefined);

    const getSortedGalleryData = () => {
        return props.data && props.selectedItems && props.selectedFirst
            ? DataUtils.groupItemsBySelection(
                  props.data,
                  props.selectedItems
              ).flat()
            : props.data;
    };

    const handleClick = (selectionKey) => {
        if (props.onClick) {
            props.onClick(selectionKey);
        }
    };

    const handleSelection = (selectionKey) => {
        if (props.onSelect) {
            if (props.selectedItems.includes(selectionKey)) {
                if (props.acceptUnselect !== false) {
                    props.onSelect(
                        props.selectedItems.filter(
                            (itemKey) => itemKey !== selectionKey
                        )
                    );
                }
            } else if (props.selectionLimit === 1) {
                props.onSelect([selectionKey]);
            } else if (
                !props.selectionLimit ||
                props.selectionLimit >= props.selectedItems.length + 1
            ) {
                props.onSelect([...props.selectedItems, selectionKey]);
            }
        }
    };

    return (
        <div className="gallery">
            <Tooltip show={Boolean(tooltipContent)} content={tooltipContent} />
            {props.loading ? (
                <div className="gallery_text">
                    <FaSpinner className="gallery_loading_icon" />
                    {props.loadingText ? props.loadingText : 'Loading...'}
                </div>
            ) : props.data && props.data.length > 0 ? (
                getSortedGalleryData().map((item, index) => (
                    <GalleryImage
                        key={index}
                        alt={item.alt}
                        src={item.src}
                        watermark={item.watermark}
                        isSelected={
                            props.selectedItems &&
                            props.selectedItems.includes(item.id)
                        }
                        onMouseOver={() =>
                            item.tooltipContent &&
                            setTooltipContent(item.tooltipContent)
                        }
                        onMouseOut={() => setTooltipContent(undefined)}
                        onClick={() =>
                            props.selectedItems
                                ? handleSelection(item.id)
                                : handleClick(item.id)
                        }
                    />
                ))
            ) : (
                <div className="gallery_text">
                    {props.noContentText ? props.noContentText : 'No Content'}
                </div>
            )}
        </div>
    );
}

export default Gallery;
