import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import CommonConstants from '../../../constants/CommonConstants';
import Divider from '../divider/Divider';

import './FileInput.css';

function FileInput(props) {
    const [file, setFile] = useState(undefined);
    const inputRef = useRef(null);

    useEffect(() => {
        props.onChange(file);
        // eslint-disable-next-line
    }, [file]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            setFile(acceptedFiles[0]);
        }
    }, []);

    const convertByteToMB = (byte) => {
        return (byte / 1000000).toFixed(2);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const renderText = () => {
        if (isDragActive) {
            return (
                <div className="file_input_text drag_active">
                    Drop the file to upload.
                </div>
            );
        } else if (file) {
            return (
                <div className="file_input_text">
                    ({convertByteToMB(file.size)}MB) {file.name}
                </div>
            );
        } else {
            return (
                <div className="file_input_text not_selected">
                    {props.placeholder
                        ? props.placeholder
                        : CommonConstants.NOT_SELECTED_TEXT}
                </div>
            );
        }
    };

    return (
        <div className="file_input" {...getRootProps()}>
            {file ? (
                <button
                    className="hollow_button clear"
                    onClick={() => {
                        inputRef.current.value = '';
                        setFile(undefined);
                    }}
                >
                    Clear
                </button>
            ) : (
                <button
                    className="hollow_button upload"
                    onClick={() => {
                        inputRef.current.click();
                    }}
                >
                    Upload File
                </button>
            )}
            <Divider />
            {renderText()}
            <input
                {...getInputProps()}
                id={props.id}
                ref={inputRef}
                className="hidden_input"
                type="file"
                onChange={(e) => {
                    setFile(e.target.files[0]);
                }}
            />
        </div>
    );
}

export default FileInput;
