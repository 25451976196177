import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import AxiosUtils from '../utils/AxiosUtils';
import LocalStorage from '../constants/LocalStorageConstants';

class AuthService {
    static decodeJwt(token) {
        try {
            return jwtDecode(token);
        } catch (error) {
            return null;
        }
    }

    static async getAccessToken(email, password) {
        try {
            const data = {
                email: email,
                password: password,
                refreshToken: '',
            };
            const response = await axios.post('/session', data);
            const decodedToken = AuthService.decodeJwt(
                response.data['authToken']
            );
            localStorage[LocalStorage.USER_EMAIL] = decodedToken['sub'];
            localStorage[LocalStorage.USER_ID] = decodedToken['userId'];
            localStorage[LocalStorage.USER_ROLE] = decodedToken['userRole'];
            localStorage[LocalStorage.ACCESS_TOKEN] =
                response.data['authToken'];
            localStorage[LocalStorage.REFRESH_TOKEN] =
                response.data['refreshToken'];
            AxiosUtils.setAuthHeader();
        } catch (error) {
            throw new Error('Authentication failed.');
        }
    }

    static isTokenValid(token) {
        const currentTime = Date.now() / 1000;
        if (jwtDecode(token).exp < currentTime) {
            return false;
        } else {
            return true;
        }
    }

    static async refreshAccessTokenIfExpired() {
        try {
            if (
                !AuthService.isTokenValid(
                    localStorage[LocalStorage.ACCESS_TOKEN]
                )
            ) {
                const data = {
                    email: localStorage[LocalStorage.USER_EMAIL],
                    password: '',
                    refreshToken: localStorage[LocalStorage.REFRESH_TOKEN],
                };
                const response = await axios.post('/session', data);
                localStorage[LocalStorage.ACCESS_TOKEN] =
                    response.data['authToken'];
                localStorage[LocalStorage.REFRESH_TOKEN] =
                    response.data['refreshToken'];
                AxiosUtils.setAuthHeader();
            }
        } catch (error) {
            throw new Error('Authentication failed.');
        }
    }
}

export default AuthService;
