// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    z-index: 4;
    position: fixed;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 200px;
    font-size: 0.8rem;
    text-wrap: unset;
    word-wrap: break-word;
    word-break: break-word;
    border-radius: 8px;
    color: gray;
    border: 2px solid lightgray;
    background-color: white;
}

.tooltip.hidden {
    visibility: hidden;
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".tooltip {\n    z-index: 4;\n    position: fixed;\n    pointer-events: none;\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n    gap: 10px;\n    height: fit-content;\n    width: fit-content;\n    max-width: 200px;\n    font-size: 0.8rem;\n    text-wrap: unset;\n    word-wrap: break-word;\n    word-break: break-word;\n    border-radius: 8px;\n    color: gray;\n    border: 2px solid lightgray;\n    background-color: white;\n}\n\n.tooltip.hidden {\n    visibility: hidden;\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
