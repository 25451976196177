// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text_box {
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 42px;
    border-radius: 8px;
    background-color: whitesmoke;
    border: 2px solid lightgray;
    text-decoration: none;
    text-wrap: nowrap;
}

.text_box.faded {
    color: gray;
}

.text_box.wrap {
    text-wrap: unset;
    word-wrap: break-word;
    word-break: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/text_box/TextBox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,4BAA4B;IAC5B,2BAA2B;IAC3B,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".text_box {\n    font-size: 1rem;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 15px;\n    min-height: 42px;\n    border-radius: 8px;\n    background-color: whitesmoke;\n    border: 2px solid lightgray;\n    text-decoration: none;\n    text-wrap: nowrap;\n}\n\n.text_box.faded {\n    color: gray;\n}\n\n.text_box.wrap {\n    text-wrap: unset;\n    word-wrap: break-word;\n    word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
