// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden_select {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.dropdown {
    width: 100%;
    display: flex;
    height: 42px;
    border-radius: 8px;
    border: 2px solid lightgray;
    position: relative;
}

.dropdown_header {
    flex: 1 1;
    color: gray;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
}

.dropdown_header.selected {
    color: black;
}

.dropdown_icon {
    color: lightgray;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/dropdown/Dropdown.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,SAAO;IACP,WAAW;IACX,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".hidden_select {\n    opacity: 0;\n    width: 100%;\n    height: 100%;\n    position: absolute;\n}\n\n.dropdown {\n    width: 100%;\n    display: flex;\n    height: 42px;\n    border-radius: 8px;\n    border: 2px solid lightgray;\n    position: relative;\n}\n\n.dropdown_header {\n    flex: 1;\n    color: gray;\n    font-size: 1rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 10px 15px;\n}\n\n.dropdown_header.selected {\n    color: black;\n}\n\n.dropdown_icon {\n    color: lightgray;\n    font-size: 1rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
