// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monaco_editor {
    width: 100%;
    border: 2px solid lightgray;
}

.monaco_editor.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading {
    color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loading_text {
    font-weight: bold;
}

.loading_icon {
    font-size: 1.5rem;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/monaco_editor/MonacoEditor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,0BAA0B;IAC1B,mCAAmC;IACnC,iCAAiC;AACrC","sourcesContent":[".monaco_editor {\n    width: 100%;\n    border: 2px solid lightgray;\n}\n\n.monaco_editor.loading {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n@keyframes spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.loading {\n    color: lightgray;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n\n.loading_text {\n    font-weight: bold;\n}\n\n.loading_icon {\n    font-size: 1.5rem;\n    animation-name: spin;\n    animation-duration: 3000ms;\n    animation-iteration-count: infinite;\n    animation-timing-function: linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
