import DataUtils from './DataUtils';

class TypeClassUtils {
    static createTypeClassDTO(
        typeClassName,
        typeClassDescriptionKey,
        typeClassAssetIds
    ) {
        return {
            name: DataUtils.setString(typeClassName),
            descriptionKey: DataUtils.setString(typeClassDescriptionKey),
            assetIds: typeClassAssetIds,
        };
    }
}

export default TypeClassUtils;
