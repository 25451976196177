import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import ModifierService from '../../../services/ModifierService';
import StateConstants from '../../../constants/StateConstants';
import useWindowSize from '../../../providers/WindowProvider';
import EntityService from '../../../services/EntityService';
import WaveFormModal from './WaveFormModal';
import StateContext from '../../../providers/StateProvider';
import SkillService from '../../../services/SkillService';
import LevelService from '../../../services/LevelService';
import AssetService from '../../../services/AssetService';
import ErrorUtils from '../../../utils/ErrorUtils';
import LevelUtils from '../../../utils/LevelUtils';
import LevelForm from './LevelForm';
import LevelList from './LevelList';

function Level() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const levelStates = StateConstants.LevelStates;
    const shopStates = StateConstants.ShopStates;
    const [waveFormData, setWaveFormData] = useState(undefined);
    const [waveFormVisible, setWaveFormVisible] = useState(false);

    // Data
    const [loadingModifiers, setLoadingModifiers] = useState(true);
    const [loadingEntities, setLoadingEntities] = useState(true);
    const [loadingSkills, setLoadingSkills] = useState(true);
    const [loadingLevels, setLoadingLevels] = useState(true);
    const [loadingAssets, setLoadingAssets] = useState(true);

    // Function
    const getEntities = async () => {
        setLoadingEntities(true);
        await EntityService.getAllEntities()
            .then((response) => {
                setState(commonStates.ENTITIES, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingEntities(false);
    };

    const getSkills = async () => {
        setLoadingSkills(true);
        await SkillService.getAllSkills()
            .then((response) => {
                setState(commonStates.SKILLS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingSkills(false);
    };

    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setLoadingAssets(false);
    };

    const getModifiers = async () => {
        setLoadingModifiers(true);
        await ModifierService.getAllModifiers()
            .then((response) => setState(commonStates.MODIFIERS, response.data))
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingModifiers(false);
    };

    const getLevels = async () => {
        setLoadingLevels(true);
        await LevelService.getAllLevels()
            .then((response) => {
                setState(commonStates.LEVELS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingLevels(false);
    };

    const createLevel = async () => {
        const notificationId = raiseNotification('Creating level...', {
            permanent: true,
        });
        const levelShop = LevelUtils.createShopDTO(
            state[shopStates.RESET_TIME_IN_SECONDS],
            state[shopStates.GOLD_INCREASE_PER_REROLL],
            state[shopStates.INITIAL_REROLL_COUNT],
            state[shopStates.INITIAL_REROLL_COST],
            state[shopStates.INITIAL_FREE_REROLLS],
            state[shopStates.REROLL_COUNT_INCREASE_TIME_IN_SECONDS],
            state[shopStates.REROLL_COUNT_INCREASE_AMOUNT],
            state[shopStates.REROLL_COST_INCREASE_PER_REROLL]
        );
        const data = LevelUtils.createLevelDTO(
            state[levelStates.NAME],
            state[levelStates.DESC_KEY],
            state[levelStates.GAME_MODE],
            state[levelStates.PRIORITY],
            state[levelStates.STARTING_GOLD],
            state[levelStates.INITIAL_PLAYER_INCOME],
            state[levelStates.INITIAL_HP],
            state[levelStates.INITIAL_HP_REGEN],
            state[levelStates.INCOME_INCREASE_PER_SECOND],
            state[levelStates.INCOME_INCREASE_AMOUNT],
            state[levelStates.MODIFIER_IDS],
            state[levelStates.SKILL_POOL],
            state[levelStates.MODIFIER_POOL],
            Object.values(state[levelStates.GROUPED_SHOWCASE_ASSET_IDS]).flat(),
            levelShop,
            state[levelStates.WAVES]
        );
        await LevelService.createLevel(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Level was created. ID: ${response.data['id']}`,
                    {
                        type: 'success',
                    }
                );
                getLevels();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const openWaveForm = (wave = undefined) => {
        setWaveFormData(wave);
        setWaveFormVisible(true);
    };

    const createWave = (wave) => {
        setState(levelStates.WAVES, [...state[levelStates.WAVES], wave]);
        raiseNotification('Wave was added.', { type: 'success' });
    };

    const deleteWave = (index) => {
        setState(
            levelStates.WAVES,
            state[levelStates.WAVES].filter(
                (_, itemIndex) => itemIndex !== index
            )
        );
        raiseNotification('Wave was deleted.');
    };

    // Startup
    useEffect(() => {
        getModifiers();
        getEntities();
        getSkills();
        getLevels();
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <WaveFormModal
                visible={waveFormVisible}
                onClose={setWaveFormVisible}
                loadingModifiers={loadingModifiers}
                loadingEntities={loadingEntities}
                wave={waveFormData}
                createWave={createWave}
            />
            <LevelForm
                loadingModifiers={loadingModifiers}
                loadingEntities={loadingEntities}
                loadingSkills={loadingSkills}
                loadingAssets={loadingAssets}
                createLevel={createLevel}
                openWaveForm={openWaveForm}
                deleteWave={deleteWave}
            />
            <LevelList
                loading={
                    loadingModifiers ||
                    loadingEntities ||
                    loadingSkills ||
                    loadingLevels ||
                    loadingAssets
                }
            />
        </div>
    );
}

export default Level;
