import { useRef } from 'react';

import { FaCheck, FaMinus, FaPlus, FaSpinner } from 'react-icons/fa';

import './Table.css';

function TableColumn(props) {
    const columnRef = useRef(null);
    const splitterRef = useRef(null);
    let startX, columnWidth;

    const resize = (e) => {
        const newWidth = columnWidth + (e.clientX - startX);
        columnRef.current.style.width = `${newWidth}px`;
    };

    const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
    };

    return (
        <div className="table_column">
            <div
                className={
                    props.resize !== false
                        ? 'table_column_content'
                        : 'table_column_content fit_content'
                }
                ref={columnRef}
            >
                <div className="table_cell header">{props.header}</div>
                {props.data.map((item, index) => (
                    <div key={index} className="table_cell">
                        {item}
                    </div>
                ))}
            </div>
            <div
                className={
                    props.resize !== false
                        ? 'table_column_splitter'
                        : 'table_column_splitter disabled'
                }
                ref={splitterRef}
                onMouseDown={
                    props.resize !== false
                        ? (e) => {
                              startX = e.clientX;
                              columnWidth = columnRef.current.clientWidth;
                              window.addEventListener('mousemove', resize);
                              window.addEventListener('mouseup', stopResize);
                          }
                        : undefined
                }
            ></div>
        </div>
    );
}

function TableSelectionColumn(props) {
    const handleSelection = (selectionKey) => {
        if (props.onSelect) {
            if (props.selectedItems.includes(selectionKey)) {
                if (props.acceptUnselect !== false) {
                    props.onSelect(
                        props.selectedItems.filter(
                            (itemKey) => itemKey !== selectionKey
                        )
                    );
                }
            } else if (props.selectionLimit === 1) {
                props.onSelect([selectionKey]);
            } else if (
                !props.selectionLimit ||
                props.selectionLimit >= props.selectedItems.length + 1
            ) {
                props.onSelect([...props.selectedItems, selectionKey]);
            }
        }
    };

    return (
        <div className="table_column">
            <div className="table_column_content fit_content">
                <div className="table_cell header"></div>
                {props.selectionKeys.map((selectionKey, index) => (
                    <div key={index} className="table_cell">
                        <div
                            className={
                                props.selectedItems.includes(selectionKey)
                                    ? 'table_icon_circle selected'
                                    : 'table_icon_circle'
                            }
                            onClick={() => handleSelection(selectionKey)}
                        >
                            {props.selectedItems.includes(selectionKey) ? (
                                <FaCheck />
                            ) : undefined}
                        </div>
                    </div>
                ))}
            </div>
            <div className="table_column_splitter disabled"></div>
        </div>
    );
}

function TableAdditionColumn(props) {
    const getCount = (list, item) => {
        return list.filter((list_item) => list_item === item).length;
    };

    const handleAdd = (selectionKey) => {
        if (props.onSelect) {
            props.onSelect([...props.selectedItems, selectionKey]);
        }
    };

    const handleRemove = (selectionKey) => {
        if (props.onSelect && props.selectedItems.includes(selectionKey)) {
            const keyIndex = props.selectedItems.indexOf(selectionKey);
            props.onSelect(
                props.selectedItems.filter((_, index) => index !== keyIndex)
            );
        }
    };

    return (
        <div className="table_column">
            <div className="table_column_content addition">
                <div className="table_cell header">Count</div>
                {props.selectionKeys.map((selectionKey, index) => {
                    return (
                        <div key={index} className="table_cell">
                            <div
                                className={
                                    getCount(
                                        props.selectedItems,
                                        selectionKey
                                    ) > 0
                                        ? 'table_icon_circle remove'
                                        : 'table_icon_circle'
                                }
                                onClick={() => handleRemove(selectionKey)}
                            >
                                <FaMinus />
                            </div>
                            <p>{getCount(props.selectedItems, selectionKey)}</p>
                            <div
                                className="table_icon_circle add"
                                onClick={() => handleAdd(selectionKey)}
                            >
                                <FaPlus />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="table_column_splitter disabled"></div>
        </div>
    );
}

function Table(props) {
    return (
        <div className="table">
            <div className="table_column_splitter disabled"></div>
            {props.loading ? (
                props.columns.map((column, index) => (
                    <TableColumn
                        key={index}
                        header={column.header}
                        data={
                            index === 0
                                ? [<FaSpinner className="table_loading_icon" />]
                                : [undefined]
                        }
                    />
                ))
            ) : (
                <>
                    {props.selectedItems &&
                        (props.multiInstanceSelection ? (
                            <TableAdditionColumn
                                onSelect={props.onSelect}
                                selectedItems={props.selectedItems}
                                selectionKeys={props.data.map(
                                    (item) => item[props.selectionKey]
                                )}
                            />
                        ) : (
                            <TableSelectionColumn
                                onSelect={props.onSelect}
                                selectionLimit={props.selectionLimit}
                                selectedItems={props.selectedItems}
                                selectionKeys={props.data.map(
                                    (item) => item[props.selectionKey]
                                )}
                                acceptUnselect={props.acceptUnselect}
                            />
                        ))}
                    {props.columns.map((column, index) => (
                        <TableColumn
                            key={index}
                            header={column.header}
                            data={props.data.map((item) => item[column.field])}
                            resize={column.resize}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

export default Table;
