// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden_input {
    display: none;
    visibility: hidden;
}

.file_input {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid lightgray;
}

.file_input .hollow_button {
    width: 120px;
    color: gray;
}

.file_input .hollow_button.upload:hover {
    color: dodgerblue;
}

.file_input .hollow_button.clear:hover {
    color: tomato;
}

.file_input_text {
    flex: 1 1;
    padding: 10px 15px;
    font-size: 1rem;
    line-height: 42px;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
}

.file_input_text.drag_active {
    color: dodgerblue;
}

.file_input_text.not_selected {
    color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/file_input/FileInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".hidden_input {\n    display: none;\n    visibility: hidden;\n}\n\n.file_input {\n    width: 100%;\n    height: 42px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 8px;\n    border: 2px solid lightgray;\n}\n\n.file_input .hollow_button {\n    width: 120px;\n    color: gray;\n}\n\n.file_input .hollow_button.upload:hover {\n    color: dodgerblue;\n}\n\n.file_input .hollow_button.clear:hover {\n    color: tomato;\n}\n\n.file_input_text {\n    flex: 1;\n    padding: 10px 15px;\n    font-size: 1rem;\n    line-height: 42px;\n    text-align: left;\n    text-wrap: nowrap;\n    overflow: hidden;\n}\n\n.file_input_text.drag_active {\n    color: dodgerblue;\n}\n\n.file_input_text.not_selected {\n    color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
