// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_data_field {
    font-size: 0.8rem;
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-word;
    color: gray;
}

.user_data_row {
    display: flex;
    gap: 10px;
}

.user_data_row .flex {
    flex: 1 1;
}

.data_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/settings_page/Settings.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".user_data_field {\n    font-size: 0.8rem;\n    font-weight: bold;\n    word-wrap: break-word;\n    word-break: break-word;\n    color: gray;\n}\n\n.user_data_row {\n    display: flex;\n    gap: 10px;\n}\n\n.user_data_row .flex {\n    flex: 1;\n}\n\n.data_box {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
