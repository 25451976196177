import { FaSpinner } from 'react-icons/fa';
import './Loading.css';

function Loading() {
    return (
        <div className="loading_page">
            <FaSpinner className="loading_spinner" />
        </div>
    );
}

export default Loading;
