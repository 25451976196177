import { useContext } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormAssetFields from '../../common/form_asset_fields/FormAssetFields';
import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import TextInput from '../../common/text_input/TextInput';
import Dropdown from '../../common/dropdown/Dropdown';
import Divider from '../../common/divider/Divider';
import Drawer from '../../common/drawer/Drawer';
import Table from '../../common/table/Table';

const SkillForm = (props) => {
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const skillStates = StateConstants.SkillStates;
    const damageTypeId = state[skillStates.DAMAGE_TYPE_ID];

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Skill</h2>
                <FormFieldTitle title="Skill Name (String)">
                    <TextInput
                        value={state[skillStates.NAME]}
                        onChange={(value) => setState(skillStates.NAME, value)}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={state[skillStates.DESC_KEY]}
                        onChange={(value) =>
                            setState(skillStates.DESC_KEY, value)
                        }
                    />
                </FormFieldTitle>
                <div className="page_row bottom">
                    <FormFieldTitle title="Projectile Speed (Float)">
                        <TextInput
                            type="float"
                            value={state[skillStates.PROJ_SPEED]}
                            onChange={(value) =>
                                setState(skillStates.PROJ_SPEED, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Range (Float)">
                        <TextInput
                            type="float"
                            value={state[skillStates.RANGE]}
                            onChange={(value) =>
                                setState(skillStates.RANGE, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <FormFieldTitle title="Price (Float)">
                    <TextInput
                        type="float"
                        value={state[skillStates.PRICE]}
                        onChange={(value) => setState(skillStates.PRICE, value)}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Skill Modifiers">
                    <Drawer
                        text={
                            state[skillStates.MODIFIER_IDS].length > 0
                                ? `Selected ${
                                      state[skillStates.MODIFIER_IDS].length
                                  } modifier(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingModifiers}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.MODIFIERS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={state[skillStates.MODIFIER_IDS]}
                                onSelect={(value) =>
                                    setState(skillStates.MODIFIER_IDS, value)
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Damage</h3>
                <FormFieldTitle title="Damage (Float)">
                    <TextInput
                        type="float"
                        value={state[skillStates.DAMAGE]}
                        onChange={(value) =>
                            setState(skillStates.DAMAGE, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Damage Type">
                    <Drawer
                        text={
                            damageTypeId
                                ? `Selected Damage Type ID: ${damageTypeId}`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingTypeClasses}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.TYPE_CLASSES].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectionLimit={1}
                                selectedItems={
                                    damageTypeId ? [damageTypeId] : []
                                }
                                onSelect={(value) =>
                                    setState(
                                        skillStates.DAMAGE_TYPE_ID,
                                        value.length > 0 ? value[0] : undefined
                                    )
                                }
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Attack</h3>
                <FormFieldTitle title="Attack Speed (Float)">
                    <TextInput
                        type="float"
                        value={state[skillStates.ATTACK_SPEED]}
                        onChange={(value) =>
                            setState(skillStates.ATTACK_SPEED, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Attack Type">
                    <Dropdown
                        value={state[skillStates.ATTACK_TYPE]}
                        onChange={(value) =>
                            setState(skillStates.ATTACK_TYPE, value)
                        }
                    >
                        {Object.entries(EnumerationConstants.AttackTypes).map(
                            ([type, title]) => (
                                <option key={type} value={type}>
                                    {title}
                                </option>
                            )
                        )}
                    </Dropdown>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Assets</h3>
                <FormAssetFields
                    loading={props.loadingAssets}
                    selectedAssetsStateKey={skillStates.GROUPED_ASSET_IDS}
                />
                <div className="page_row right">
                    <button
                        className="solid_button blue"
                        onClick={props.createSkill}
                    >
                        Create Skill
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SkillForm;
