import './ConfirmationBox.css';

function ConfirmationBox(props) {
    return (
        <div className="confirmation_box">
            {props.text && (
                <p className="confirmation_box_text">{props.text}</p>
            )}
            <div className="confirmation_box_buttons">
                <button
                    className="solid_button confirmation_box_button red"
                    onClick={props.onAccept}
                >
                    {props.acceptButtonText ? props.acceptButtonText : 'Yes'}
                </button>
                <button
                    className="solid_button confirmation_box_button blue"
                    onClick={props.onReject}
                >
                    {props.rejectButtonText ? props.rejectButtonText : 'No'}
                </button>
            </div>
        </div>
    );
}

export default ConfirmationBox;
