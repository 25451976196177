import axios from 'axios';

import AuthService from './AuthService';

class SkillService {
    static async createSkill(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/skills', data);
        return response;
    }

    static async getAllSkills() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/skills');
        return response;
    }
}

export default SkillService;
