import DataUtils from './DataUtils';

class SkillUtils {
    static createSkillDTO(
        skillName,
        skillDescriptionKey,
        skillProjectileSpeed,
        skillRange,
        skillPrice,
        skillModifierIds,
        skillAttackSpeed,
        skillAttackType,
        skillDamage,
        skillDamageTypeId,
        skillAssetIds
    ) {
        return {
            name: DataUtils.setString(skillName),
            descriptionKey: DataUtils.setString(skillDescriptionKey),
            projectileSpeed: DataUtils.setFloat(skillProjectileSpeed),
            range: DataUtils.setFloat(skillRange),
            price: DataUtils.setFloat(skillPrice),
            modifierIds: skillModifierIds,
            assetIds: skillAssetIds,
            attackSpeed: DataUtils.setFloat(skillAttackSpeed),
            attackType: DataUtils.setString(skillAttackType),
            damage: DataUtils.setFloat(skillDamage),
            damageTypeId: DataUtils.setInt(skillDamageTypeId),
        };
    }

    static createSkillLabelData(skill) {
        return [
            { title: 'ID', value: skill.id },
            { title: 'Skill Name', value: skill.name },
            { title: 'Desc. Key', value: skill.descriptionKey },
            { title: 'Damage', value: skill.damage },
            { title: 'Attack Speed', value: skill.attackSpeed },
            { title: 'Attack Type', value: skill.attackType },
            { title: 'Projectile Speed', value: skill.projectileSpeed },
            { title: 'Range', value: skill.range },
            { title: 'Price', value: skill.price },
            { title: 'Created At', value: skill.createdAt },
        ];
    }
}

export default SkillUtils;
