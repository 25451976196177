import EnumerationConstants from '../constants/EnumerationConstants';
import DataUtils from './DataUtils';

class AssetUtils {
    static async createAssetDTO(assetType, assetReference, assetImage) {
        return {
            assetType: DataUtils.setString(assetType),
            assetReference: DataUtils.setString(
                assetReference && assetType && `${assetReference}-${assetType}`
            ),
            image: await DataUtils.setFile(assetImage),
        };
    }

    static filterAssets(assets, referenceSearch, assetType) {
        return assets.filter((asset) => {
            const regex = new RegExp(referenceSearch);
            const isReferenceMatch = regex.test(asset.assetReference);
            const isTypeMatch =
                !Boolean(assetType) || asset.assetType === assetType;
            return isReferenceMatch && isTypeMatch;
        });
    }

    static createImageData(asset) {
        return [
            { title: 'ID', value: asset.id },
            { title: 'Asset Reference', value: asset.assetReference },
            { title: 'Asset Type', value: asset.assetType },
            { title: 'Created At', value: asset.createdAt },
        ];
    }

    static createGalleryData(assets) {
        return assets.map((item) => ({
            id: item.id,
            alt: item.assetReference,
            src: item.imageUrl,
            tooltipContent: this.createImageData(item),
            watermark: EnumerationConstants.AssetTypes[item.assetType].label,
        }));
    }
}

export default AssetUtils;
