import axios from 'axios';

import AuthService from './AuthService';

class SkinService {
    static async createSkin(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/skins', data);
        return response;
    }

    static async getAllSkins() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/skins');
        return response;
    }
}

export default SkinService;
