import axios from 'axios';

import AuthService from './AuthService';

class AssetService {
    static async createAsset(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/assets', data);
        return response;
    }

    static async deleteAsset(assetId) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.delete(`/assets/${assetId}`);
        return response;
    }

    static async getAllAssets() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/assets');
        return response;
    }
}

export default AssetService;
