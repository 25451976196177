import { useEffect, useRef } from 'react';

import useWindowSize from '../../../providers/WindowProvider';

import './Tooltip.css';

function Tooltip(props) {
    const tooltipRef = useRef();
    const windowSize = useWindowSize();

    useEffect(() => {
        const handleTooltipTransform = (e) => {
            if (tooltipRef.current) {
                if (e.clientX > windowSize.width / 2) {
                    tooltipRef.current.style.left = 'unset';
                    tooltipRef.current.style.right = `${
                        windowSize.width - e.clientX
                    }px`;
                } else {
                    tooltipRef.current.style.right = 'unset';
                    tooltipRef.current.style.left = `${e.clientX}px`;
                }
                if (e.clientY > windowSize.height / 2) {
                    tooltipRef.current.style.top = 'unset';
                    tooltipRef.current.style.bottom = `${
                        windowSize.height - e.clientY
                    }px`;
                } else {
                    tooltipRef.current.style.bottom = 'unset';
                    tooltipRef.current.style.top = `${e.clientY}px`;
                }
            }
        };
        if (props.show) {
            document.addEventListener('mousemove', handleTooltipTransform);
        } else {
            document.removeEventListener('mousemove', handleTooltipTransform);
        }
    }, [props.show, windowSize.height, windowSize.width]);

    return (
        <div
            ref={tooltipRef}
            className={props.show ? 'tooltip' : 'tooltip hidden'}
        >
            {props.content &&
                props.content.map((item, index) => (
                    <p key={index}>
                        <b>{item.title}:</b> {item.value}
                    </p>
                ))}
        </div>
    );
}

export default Tooltip;
