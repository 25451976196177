// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text_input_outline {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    height: 42px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid lightgray;
}

.text_input {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-size: 1rem;
    padding: 10px 15px;
}

.text_input::placeholder {
    color: gray;
}

.password_toggle_icon {
    color: gray;
    padding: 10px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/text_input/TextInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":[".text_input_outline {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-height: 42px;\n    height: 42px;\n    width: 100%;\n    border-radius: 8px;\n    border: 2px solid lightgray;\n}\n\n.text_input {\n    height: 100%;\n    width: 100%;\n    border-radius: 6px;\n    font-size: 1rem;\n    padding: 10px 15px;\n}\n\n.text_input::placeholder {\n    color: gray;\n}\n\n.password_toggle_icon {\n    color: gray;\n    padding: 10px;\n    width: fit-content;\n    height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
