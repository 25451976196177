import { useContext, useEffect, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import StateConstants from '../../../constants/StateConstants';
import ModifierUtils from '../../../utils/ModifierUtils';
import StateContext from '../../../providers/StateProvider';
import AssetUtils from '../../../utils/AssetUtils';
import LevelUtils from '../../../utils/LevelUtils';
import SkillUtils from '../../../utils/SkillUtils';
import DataUtils from '../../../utils/DataUtils';
import LabelPill from '../../common/label_pill/LabelPill';
import Tooltip from '../../common/tooltip/Tooltip';
import Table from '../../common/table/Table';

function WaveLabels(props) {
    return (
        <>
            {props.waves &&
                props.waves.map((wave, index) => (
                    <LabelPill
                        key={index}
                        label={String(wave.name)}
                        onMouseOver={() =>
                            props.setTooltip(
                                LevelUtils.createWaveLabelData(wave)
                            )
                        }
                        onMouseOut={() => props.setTooltip(undefined)}
                    />
                ))}
        </>
    );
}

function ModifierLabels(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const modifiers = state[commonStates.MODIFIERS];

    const [data, setData] = useState(
        DataUtils.createDataWithCount(modifiers, props.ids)
    );

    useEffect(() => {
        setData(DataUtils.createDataWithCount(modifiers, props.ids));
    }, [modifiers, props.ids]);

    return (
        <>
            {data.map((modifier, index) => (
                <div key={index} className="table_label_count_box">
                    <LabelPill
                        label={String(modifier.id)}
                        onMouseOver={() =>
                            props.setTooltip(
                                ModifierUtils.createModifierLabelData(modifier)
                            )
                        }
                        onMouseOut={() => props.setTooltip(undefined)}
                    />
                    <p className="table_label_count">x{modifier.count}</p>
                </div>
            ))}
        </>
    );
}

function SkillLabels(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const skills = state[commonStates.SKILLS];

    const [data, setData] = useState(
        DataUtils.createDataWithCount(skills, props.ids)
    );

    useEffect(() => {
        setData(DataUtils.createDataWithCount(skills, props.ids));
    }, [skills, props.ids]);

    return (
        <>
            {data.map((skill, index) => (
                <div key={index} className="table_label_count_box">
                    <LabelPill
                        label={String(skill.id)}
                        onMouseOver={() =>
                            props.setTooltip(
                                SkillUtils.createSkillLabelData(skill)
                            )
                        }
                        onMouseOut={() => props.setTooltip(undefined)}
                    />
                    <p className="table_label_count">x{skill.count}</p>
                </div>
            ))}
        </>
    );
}

function AssetLabels(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const assets = state[commonStates.ASSETS];

    const [data, setData] = useState(
        DataUtils.createDataWithCount(assets, props.ids)
    );

    useEffect(() => {
        setData(DataUtils.createDataWithCount(assets, props.ids));
    }, [assets, props.ids]);

    return (
        <>
            {data.map((asset, index) => (
                <LabelPill
                    key={index}
                    label={
                        EnumerationConstants.AssetTypes[asset.assetType].label
                    }
                    onMouseOver={() =>
                        props.setTooltip(AssetUtils.createImageData(asset))
                    }
                    onMouseOut={() => props.setTooltip(undefined)}
                />
            ))}
        </>
    );
}

function LevelList(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [tooltipContent, setTooltipContent] = useState(undefined);
    const levels = state[commonStates.LEVELS];

    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Level List</h2>
                <Tooltip
                    show={Boolean(tooltipContent)}
                    content={tooltipContent}
                />
                <Table
                    key="id"
                    loading={props.loading}
                    columns={[
                        { header: 'ID', field: 'id' },
                        { header: 'Name', field: 'name' },
                        { header: 'Desc. Key', field: 'descKey' },
                        { header: 'Shop', field: 'shop' },
                        { header: 'Waves', field: 'waves' },
                        { header: 'Modifiers', field: 'modifiers' },
                        { header: 'Skills (Pool)', field: 'skillPool' },
                        { header: 'Modifiers (Pool)', field: 'modifierPool' },
                        { header: 'Showcase Assets', field: 'showcaseAssets' },
                        { header: 'Created At', field: 'createdAt' },
                    ]}
                    data={
                        levels
                            ? levels.map((item) => ({
                                  id: item.id,
                                  name: item.name,
                                  descKey: item.descriptionKey,
                                  shop: (
                                      <LabelPill
                                          label="Hover to view."
                                          onMouseOver={() =>
                                              setTooltipContent(
                                                  LevelUtils.createShopLabelData(
                                                      item.shop
                                                  )
                                              )
                                          }
                                          onMouseOut={() =>
                                              setTooltipContent(undefined)
                                          }
                                      />
                                  ),
                                  waves: (
                                      <WaveLabels
                                          waves={item.waves}
                                          setTooltip={setTooltipContent}
                                      />
                                  ),
                                  modifiers: (
                                      <ModifierLabels
                                          ids={item.modifierIds}
                                          setTooltip={setTooltipContent}
                                      />
                                  ),
                                  skillPool: (
                                      <SkillLabels
                                          ids={item.skillPool}
                                          setTooltip={setTooltipContent}
                                      />
                                  ),
                                  modifierPool: (
                                      <ModifierLabels
                                          ids={item.modifierPool}
                                          setTooltip={setTooltipContent}
                                      />
                                  ),
                                  showcaseAssets: (
                                      <AssetLabels
                                          ids={item.showcaseAssetIds}
                                          setTooltip={setTooltipContent}
                                      />
                                  ),
                                  createdAt: item.createdAt,
                              }))
                            : []
                    }
                />
            </div>
        </div>
    );
}

export default LevelList;
