import axios from 'axios';

import AuthService from './AuthService';

class UserService {
    static async getUserById(userId) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get(`/users/${userId}`);
        return response;
    }
}

export default UserService;
