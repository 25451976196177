import DataUtils from './DataUtils';

class LevelUtils {
    static createWaveDTO(
        waveName,
        waveDescriptionKey,
        wavePriority,
        waveDuration,
        waveModifierIds,
        waveEntityIds
    ) {
        return {
            name: DataUtils.setString(waveName),
            descriptionKey: DataUtils.setString(waveDescriptionKey),
            priority: DataUtils.setInt(wavePriority),
            duration: DataUtils.setFloat(waveDuration),
            modifierIds: waveModifierIds,
            entityIds: waveEntityIds,
        };
    }

    static createShopDTO(
        shopResetTimeInSeconds,
        shopGoldIncreasePerReRoll,
        shopInitialReRollCount,
        shopInitialReRollCost,
        shopInitialFreeReRolls,
        shopReRollCountIncreaseTimeInSeconds,
        shopReRollCountIncreaseAmount,
        shopReRollCostIncreasePerReRoll
    ) {
        return {
            resetTimeInSeconds: DataUtils.setFloat(shopResetTimeInSeconds),
            goldIncreasePerReRoll: DataUtils.setFloat(
                shopGoldIncreasePerReRoll
            ),
            initialReRollCount: DataUtils.setInt(shopInitialReRollCount),
            initialReRollCost: DataUtils.setFloat(shopInitialReRollCost),
            initialFreeReRolls: DataUtils.setInt(shopInitialFreeReRolls),
            reRollCountIncreaseTimeInSeconds: DataUtils.setFloat(
                shopReRollCountIncreaseTimeInSeconds
            ),
            reRollCountIncreaseAmount: DataUtils.setInt(
                shopReRollCountIncreaseAmount
            ),
            reRollCostIncreasePerReRoll: DataUtils.setFloat(
                shopReRollCostIncreasePerReRoll
            ),
        };
    }

    static createLevelDTO(
        levelName,
        levelDescriptionKey,
        levelGameMode,
        levelPriority,
        levelStartingGold,
        levelInitialPLayerIncome,
        levelInitialHP,
        levelInitialHPRegen,
        levelIncomeIncreasePerSeconds,
        levelIncomeIncreaseAmount,
        levelModifierIds,
        levelSkillPool,
        levelModifierPool,
        levelShowcaseAssetIds,
        levelShop,
        levelWaves
    ) {
        return {
            name: DataUtils.setString(levelName),
            descriptionKey: DataUtils.setString(levelDescriptionKey),
            gameMode: DataUtils.setString(levelGameMode),
            priority: DataUtils.setInt(levelPriority),
            startingGold: DataUtils.setFloat(levelStartingGold),
            initialPlayerIncome: DataUtils.setFloat(levelInitialPLayerIncome),
            initialHP: DataUtils.setFloat(levelInitialHP),
            initialHPRegen: DataUtils.setFloat(levelInitialHPRegen),
            incomeIncreasePerSeconds: DataUtils.setFloat(
                levelIncomeIncreasePerSeconds
            ),
            incomeIncreaseAmount: DataUtils.setFloat(levelIncomeIncreaseAmount),
            modifierIds: levelModifierIds,
            skillPool: levelSkillPool,
            modifierPool: levelModifierPool,
            showcaseAssetIds: levelShowcaseAssetIds,
            shop: levelShop,
            waves: levelWaves,
        };
    }

    static createShopLabelData(shop) {
        return [
            { title: 'Reset Time In Seconds', value: shop.resetTimeInSeconds },
            {
                title: 'Gold Increase Per Re-Roll',
                value: shop.goldIncreasePerReRoll,
            },
            { title: 'Initial Re-Roll Count', value: shop.initialReRollCount },
            { title: 'Initial Re-Roll Cost', value: shop.initialReRollCost },
            { title: 'Initial Free Re-Rolls', value: shop.initialFreeReRolls },
            {
                title: 'Re-Roll Count Increase Time In Seconds',
                value: shop.reRollCountIncreaseTimeInSeconds,
            },
            {
                title: 'Re-Roll Count Increase Amount',
                value: shop.reRollCountIncreaseAmount,
            },
            {
                title: 'Re-Roll Cost Increase Per Re-Roll',
                value: shop.reRollCostIncreasePerReRoll,
            },
        ];
    }

    static createWaveLabelData(wave) {
        const modifiers =
            wave.modifierIds && wave.modifierIds.length > 0
                ? DataUtils.createDataWithCount([], wave.modifierIds)
                      .map((item) => `[${item.id}]x${item.count}`)
                      .join(', ')
                : '-';
        const entities =
            wave.entityIds && wave.entityIds.length > 0
                ? DataUtils.createDataWithCount([], wave.entityIds)
                      .map((item) => `[${item.id}]x${item.count}`)
                      .join(', ')
                : '-';
        return [
            { title: 'ID', value: wave.id },
            { title: 'Wave Name', value: wave.name },
            { title: 'Desc. Key', value: wave.descriptionKey },
            { title: 'Priority', value: wave.priority },
            { title: 'Duration', value: wave.duration },
            { title: 'Modifiers', value: modifiers },
            { title: 'Entities', value: entities },
        ];
    }
}

export default LevelUtils;
