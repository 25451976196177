import { useContext, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import AssetUtils from '../../../utils/AssetUtils';
import TextInput from '../../common/text_input/TextInput';
import Dropdown from '../../common/dropdown/Dropdown';
import Gallery from '../../common/gallery/Gallery';

function AssetList(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [assetFilterReference, setAssetFilterReference] = useState('');
    const [assetFilterType, setAssetFilterType] = useState('');
    const assets = state[commonStates.ASSETS].sort((a, b) =>
        a.assetType < b.assetType ? -1 : 1
    );

    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Asset Gallery</h2>
                {!props.loadingAssets && (
                    <div className="page_row bottom">
                        <FormFieldTitle title="Search By Reference">
                            <TextInput onChange={setAssetFilterReference} />
                        </FormFieldTitle>
                        <FormFieldTitle title="Filter By Asset Type">
                            <Dropdown
                                id="assetFilterType"
                                onChange={setAssetFilterType}
                                defaultValue=""
                            >
                                <option key="" value="">
                                    All
                                </option>
                                {Object.entries(
                                    EnumerationConstants.AssetTypes
                                ).map(([type, values]) => (
                                    <option key={type} value={type}>
                                        {values.title}
                                    </option>
                                ))}
                            </Dropdown>
                        </FormFieldTitle>
                    </div>
                )}
                <Gallery
                    loading={props.loadingAssets}
                    data={AssetUtils.createGalleryData(
                        AssetUtils.filterAssets(
                            assets,
                            assetFilterReference,
                            assetFilterType
                        )
                    )}
                    onClick={props.setAssetId}
                />
            </div>
        </div>
    );
}

export default AssetList;
