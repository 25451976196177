import { useContext, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import AssetUtils from '../../../utils/AssetUtils';
import LabelPill from '../../common/label_pill/LabelPill';
import Tooltip from '../../common/tooltip/Tooltip';
import Table from '../../common/table/Table';

const ModifierList = (props) => {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [tooltipContent, setTooltipContent] = useState(undefined);

    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Modifier List</h2>
                <Tooltip
                    show={Boolean(tooltipContent)}
                    content={tooltipContent}
                />
                <Table
                    key="id"
                    loading={props.loading}
                    columns={[
                        { header: 'ID', field: 'id' },
                        { header: 'Name', field: 'name' },
                        { header: 'Desc. Key', field: 'descKey' },
                        { header: 'Modifier Type', field: 'modifierType' },
                        { header: 'Expiration', field: 'expiration' },
                        { header: 'Value', field: 'value' },
                        { header: 'Price', field: 'price' },
                        { header: 'Assets', field: 'assets' },
                        { header: 'Created At', field: 'createdAt' },
                    ]}
                    data={state[commonStates.MODIFIERS].map((item) => ({
                        id: item.id,
                        name: item.name,
                        descKey: item.descriptionKey,
                        modifierType: item.modifierType,
                        expiration: item.expiration,
                        value: item.value,
                        price: item.price,
                        assets: item.assets.map((asset, index) => (
                            <LabelPill
                                key={index}
                                label={
                                    EnumerationConstants.AssetTypes[
                                        asset.assetType
                                    ].label
                                }
                                onMouseOver={() =>
                                    setTooltipContent(
                                        AssetUtils.createImageData(asset)
                                    )
                                }
                                onMouseOut={() => setTooltipContent(undefined)}
                            />
                        )),
                        createdAt: item.createdAt,
                    }))}
                />
            </div>
        </div>
    );
};

export default ModifierList;
