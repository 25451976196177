// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_pill {
    min-height: 20px;
    height: 20px;
    max-height: 20px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 5px;
    color: white;
    border: 2px solid white;
    background-color: gray;
    text-align: center;
    font-weight: bold;
    font-size: 0.7rem;
    cursor: default;
}

.label_pill.clickable {
    cursor: pointer;
}

.label_pill.lightgray {
    background-color: lightgray;
}

.label_pill.red {
    background-color: crimson;
}

.label_pill.yellow {
    background-color: orange;
}

.label_pill.green {
    background-color: mediumseagreen;
}

.label_pill.blue {
    background-color: dodgerblue;
}

.label_pill.pink {
    background-color: hotpink;
}

.label_pill.purple {
    background-color: darkmagenta;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/label_pill/LabelPill.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,2BAAsB;IAAtB,sBAAsB;IACtB,uBAAkB;IAAlB,kBAAkB;IAClB,2BAAsB;IAAtB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".label_pill {\n    min-height: 20px;\n    height: 20px;\n    max-height: 20px;\n    min-width: fit-content;\n    width: fit-content;\n    max-width: fit-content;\n    border-radius: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-inline: 5px;\n    color: white;\n    border: 2px solid white;\n    background-color: gray;\n    text-align: center;\n    font-weight: bold;\n    font-size: 0.7rem;\n    cursor: default;\n}\n\n.label_pill.clickable {\n    cursor: pointer;\n}\n\n.label_pill.lightgray {\n    background-color: lightgray;\n}\n\n.label_pill.red {\n    background-color: crimson;\n}\n\n.label_pill.yellow {\n    background-color: orange;\n}\n\n.label_pill.green {\n    background-color: mediumseagreen;\n}\n\n.label_pill.blue {\n    background-color: dodgerblue;\n}\n\n.label_pill.pink {\n    background-color: hotpink;\n}\n\n.label_pill.purple {\n    background-color: darkmagenta;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
