// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
    overscroll-behavior: none;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
    background-color: whitesmoke;
    position: absolute;
}

::-webkit-scrollbar:vertical {
    width: 10px;
    max-width: 10px;
    min-width: 10px;
}

::-webkit-scrollbar:horizontal {
    height: 10px;
    max-height: 10px;
    min-height: 10px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid whitesmoke;
    border-radius: 5px;
    background-color: lightgray;
}

:root {
    color: black;
    background-color: white;
    border-color: lightgray;
}

button {
    border: none;
    outline: none;
    appearance: none;
    text-decoration: none;
}

input,
select {
    border: none;
    outline: none;
    appearance: none;
    box-shadow: none;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,SAAS;IACT,UAAU;IACV,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;;IAEI;;oCAEgC;AACpC;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;;IAEI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":["* {\n    box-sizing: border-box;\n    overscroll-behavior: none;\n    margin: 0;\n    padding: 0;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\nhtml,\nbody {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',\n        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',\n        'Helvetica Neue', sans-serif;\n}\n\n::-webkit-scrollbar {\n    background-color: whitesmoke;\n    position: absolute;\n}\n\n::-webkit-scrollbar:vertical {\n    width: 10px;\n    max-width: 10px;\n    min-width: 10px;\n}\n\n::-webkit-scrollbar:horizontal {\n    height: 10px;\n    max-height: 10px;\n    min-height: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n    border: 2px solid whitesmoke;\n    border-radius: 5px;\n    background-color: lightgray;\n}\n\n:root {\n    color: black;\n    background-color: white;\n    border-color: lightgray;\n}\n\nbutton {\n    border: none;\n    outline: none;\n    appearance: none;\n    text-decoration: none;\n}\n\ninput,\nselect {\n    border: none;\n    outline: none;\n    appearance: none;\n    box-shadow: none;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
