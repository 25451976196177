import { useContext, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import StateConstants from '../../../constants/StateConstants';
import ModifierUtils from '../../../utils/ModifierUtils';
import StateContext from '../../../providers/StateProvider';
import AssetUtils from '../../../utils/AssetUtils';
import LabelPill from '../../common/label_pill/LabelPill';
import Tooltip from '../../common/tooltip/Tooltip';
import Table from '../../common/table/Table';

function ModifierLabels(props) {
    return (
        <>
            {props.modifiers.map((modifier, index) => (
                <LabelPill
                    key={index}
                    label={String(modifier.id)}
                    onMouseOver={() =>
                        props.setTooltip(
                            ModifierUtils.createModifierLabelData(modifier)
                        )
                    }
                    onMouseOut={() => props.setTooltip(undefined)}
                />
            ))}
        </>
    );
}

const SkillList = (props) => {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [tooltipContent, setTooltipContent] = useState(undefined);

    return (
        <div className="page_section">
            <div className="page_grid">
                <h2>Skill List</h2>
                <Tooltip
                    show={Boolean(tooltipContent)}
                    content={tooltipContent}
                />
                <Table
                    key="id"
                    loading={props.loadingSkills}
                    columns={[
                        { header: 'ID', field: 'id' },
                        { header: 'Name', field: 'name' },
                        { header: 'Desc. Key', field: 'descKey' },
                        { header: 'Damage', field: 'damage' },
                        { header: 'Damage Type', field: 'damageType' },
                        { header: 'Attack Speed', field: 'attackSpeed' },
                        { header: 'Attack Type', field: 'attackType' },
                        {
                            header: 'Projectile Speed',
                            field: 'projectileSpeed',
                        },
                        { header: 'Price', field: 'price' },
                        { header: 'Assets', field: 'assets' },
                        { header: 'Modifiers', field: 'modifiers' },
                        { header: 'Created At', field: 'createdAt' },
                    ]}
                    data={state[commonStates.SKILLS].map((item) => ({
                        id: item.id,
                        name: item.name,
                        descKey: item.descriptionKey,
                        damage: item.damage,
                        damageType: `[${item.damageType.id}] ${item.damageType.name}`,
                        attackSpeed: item.attackSpeed,
                        attackType: item.attackType,
                        projectileSpeed: item.projectileSpeed,
                        price: item.price,
                        assets: item.assets.map((asset, index) => (
                            <LabelPill
                                key={index}
                                label={
                                    EnumerationConstants.AssetTypes[
                                        asset.assetType
                                    ].label
                                }
                                onMouseOver={() =>
                                    setTooltipContent(
                                        AssetUtils.createImageData(asset)
                                    )
                                }
                                onMouseOut={() => setTooltipContent(undefined)}
                            />
                        )),
                        modifiers: (
                            <ModifierLabels
                                modifiers={item.modifiers}
                                setTooltip={setTooltipContent}
                            />
                        ),
                        createdAt: item.createdAt,
                    }))}
                />
            </div>
        </div>
    );
};

export default SkillList;
