import './TextBox.css';

function TextBox(props) {
    const className = `text_box ${props.wrap ? 'wrap' : ''} ${
        props.faded ? 'faded' : ''
    }`.trim();

    return (
        <div id={props.id} className={className}>
            {props.children}
        </div>
    );
}

export default TextBox;
