import axios from 'axios';

import AuthService from './AuthService';

class ModifierService {
    static async createModifier(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/modifiers', data);
        return response;
    }

    static async getAllModifiers() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/modifiers');
        return response;
    }
}

export default ModifierService;
