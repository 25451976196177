class EnumerationConstants {
    static AssetTypes = {
        icon: { title: 'Icon', label: 'I' },
        costume: { title: 'Costume', label: 'C' },
        visualEffect: { title: 'Visual Effect', label: 'VE' },
        trailEffect: { title: 'Trail Effect', label: 'TE' },
        collisionEffect: { title: 'Collision Effect', label: 'CE' },
        departureEffect: { title: 'Departure Effect', label: 'DE' },
    };

    static ModifierTypes = {
        movementSpeed: 'Movement Speed',
        movementSpeedPercent: 'Movement Speed (%)',
        attackDamage: 'Attack Damage',
        attackDamagePercent: 'Attack Damage (%)',
        attackSpeed: 'Attack Speed',
        attackSpeedPercent: 'Attack Speed (%)',
        hpRegen: 'HP Regen',
        hpRegenPercent: 'HP Regen (%)',
        armour: 'Armour',
        armourPercent: 'Armour (%)',
    };

    static AttackTypes = {
        area: 'Area',
        barrage: 'Barrage',
        bounce: 'Bounce',
        singleTarget: 'Single Target',
        splash: 'Splash',
        waveAttack: 'Wave Attack',
    };

    static GameModes = {
        survivor: 'Survivor',
    };
}

export default EnumerationConstants;
