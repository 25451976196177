import { useContext, useEffect, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormAssetFields from '../../common/form_asset_fields/FormAssetFields';
import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import ModifierUtils from '../../../utils/ModifierUtils';
import StateContext from '../../../providers/StateProvider';
import EntityUtils from '../../../utils/EntityUtils';
import DataUtils from '../../../utils/DataUtils';
import TextInput from '../../common/text_input/TextInput';
import LabelPill from '../../common/label_pill/LabelPill';
import Dropdown from '../../common/dropdown/Dropdown';
import Tooltip from '../../common/tooltip/Tooltip';
import Divider from '../../common/divider/Divider';
import Drawer from '../../common/drawer/Drawer';
import Table from '../../common/table/Table';

function ModifierLabels(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const modifiers = state[commonStates.MODIFIERS];

    const [data, setData] = useState(
        DataUtils.createDataWithCount(modifiers, props.ids)
    );

    useEffect(() => {
        setData(DataUtils.createDataWithCount(modifiers, props.ids));
    }, [modifiers, props.ids]);

    return (
        <>
            {data.map((modifier, index) => (
                <div key={index} className="table_label_count_box">
                    <LabelPill
                        label={String(modifier.id)}
                        onMouseOver={() =>
                            props.setTooltip(
                                ModifierUtils.createModifierLabelData(modifier)
                            )
                        }
                        onMouseOut={() => props.setTooltip(undefined)}
                    />
                    <p className="table_label_count">x{modifier.count}</p>
                </div>
            ))}
        </>
    );
}

function EntityLabels(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const entities = state[commonStates.ENTITIES];

    const [data, setData] = useState(
        DataUtils.createDataWithCount(entities, props.ids)
    );

    useEffect(() => {
        setData(DataUtils.createDataWithCount(entities, props.ids));
    }, [entities, props.ids]);

    return (
        <>
            {data.map((entity, index) => (
                <div key={index} className="table_label_count_box">
                    <LabelPill
                        label={String(entity.id)}
                        onMouseOver={() =>
                            props.setTooltip(
                                EntityUtils.createEntityLabelData(entity)
                            )
                        }
                        onMouseOut={() => props.setTooltip(undefined)}
                    />
                    <p className="table_label_count">x{entity.count}</p>
                </div>
            ))}
        </>
    );
}

function LevelForm(props) {
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const levelStates = StateConstants.LevelStates;
    const shopStates = StateConstants.ShopStates;
    const skillPool = state[levelStates.SKILL_POOL];
    const modifierIds = state[levelStates.MODIFIER_IDS];
    const modifierPool = state[levelStates.MODIFIER_POOL];
    const waves = state[levelStates.WAVES];
    const [tooltipContent, setTooltipContent] = useState(undefined);

    // Functions

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Level</h2>
                <FormFieldTitle title="Level Name (String)">
                    <TextInput
                        value={state[levelStates.NAME]}
                        onChange={(value) => setState(levelStates.NAME, value)}
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={state[levelStates.DESC_KEY]}
                        onChange={(value) =>
                            setState(levelStates.DESC_KEY, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Game Mode">
                    <Dropdown
                        defaultValue={
                            Object.keys(EnumerationConstants.GameModes)[0]
                        }
                        value={state[levelStates.GAME_MODE]}
                        onChange={(value) =>
                            setState(levelStates.GAME_MODE, value)
                        }
                    >
                        {Object.entries(EnumerationConstants.GameModes).map(
                            ([type, title]) => (
                                <option key={type} value={type}>
                                    {title}
                                </option>
                            )
                        )}
                    </Dropdown>
                </FormFieldTitle>
                <FormFieldTitle title="Priority (Integer)">
                    <TextInput
                        type="int"
                        value={state[levelStates.PRIORITY]}
                        onChange={(value) =>
                            setState(levelStates.PRIORITY, value)
                        }
                    />
                </FormFieldTitle>
                <div className="page_row bottom">
                    <FormFieldTitle title="Starting Gold (Float)">
                        <TextInput
                            type="float"
                            value={state[levelStates.STARTING_GOLD]}
                            onChange={(value) =>
                                setState(levelStates.STARTING_GOLD, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Initial Player Income (Float)">
                        <TextInput
                            type="float"
                            value={state[levelStates.INITIAL_PLAYER_INCOME]}
                            onChange={(value) =>
                                setState(
                                    levelStates.INITIAL_PLAYER_INCOME,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Initial HP (Float)">
                        <TextInput
                            type="float"
                            value={state[levelStates.INITIAL_HP]}
                            onChange={(value) =>
                                setState(levelStates.INITIAL_HP, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Initial HP Regeneration (Float)">
                        <TextInput
                            type="float"
                            value={state[levelStates.INITIAL_HP_REGEN]}
                            onChange={(value) =>
                                setState(levelStates.INITIAL_HP_REGEN, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Income Increase Per Sec. (Float)">
                        <TextInput
                            type="float"
                            value={
                                state[levelStates.INCOME_INCREASE_PER_SECOND]
                            }
                            onChange={(value) =>
                                setState(
                                    levelStates.INCOME_INCREASE_PER_SECOND,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Income Increase Amount(Float)">
                        <TextInput
                            type="float"
                            value={state[levelStates.INCOME_INCREASE_AMOUNT]}
                            onChange={(value) =>
                                setState(
                                    levelStates.INCOME_INCREASE_AMOUNT,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <FormFieldTitle title="Level Modifiers">
                    <Drawer
                        text={
                            modifierIds.length > 0
                                ? `Selected ${modifierIds.length} modifier(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingModifiers}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.MODIFIERS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={modifierIds}
                                onSelect={(value) =>
                                    setState(levelStates.MODIFIER_IDS, value)
                                }
                                multiInstanceSelection
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <FormFieldTitle title="Level Skill Pool">
                    <Drawer
                        text={
                            skillPool.length > 0
                                ? `Selected ${skillPool.length} skill(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingSkills}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.SKILLS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={skillPool}
                                onSelect={(value) =>
                                    setState(levelStates.SKILL_POOL, value)
                                }
                                multiInstanceSelection
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <FormFieldTitle title="Level Modifier Pool">
                    <Drawer
                        text={
                            modifierPool.length > 0
                                ? `Selected ${modifierPool.length} modifier(s).`
                                : CommonConstants.NOT_SELECTED_TEXT
                        }
                    >
                        <div className="page_grid">
                            <Table
                                loading={props.loadingModifiers}
                                columns={[
                                    { header: 'ID', field: 'id' },
                                    { header: 'Name', field: 'name' },
                                ]}
                                data={state[commonStates.MODIFIERS].map(
                                    (item) => ({
                                        id: item.id,
                                        name: item.name,
                                    })
                                )}
                                selectionKey="id"
                                selectedItems={modifierPool}
                                onSelect={(value) =>
                                    setState(levelStates.MODIFIER_POOL, value)
                                }
                                multiInstanceSelection
                            />
                        </div>
                    </Drawer>
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Waves</h3>
                <Drawer
                    text={
                        state[levelStates.WAVES].length > 0
                            ? `Added ${
                                  state[levelStates.WAVES].length
                              } wave(s).`
                            : 'Not added.'
                    }
                >
                    <div className="page_grid">
                        <Tooltip
                            show={Boolean(tooltipContent)}
                            content={tooltipContent}
                        />
                        <button
                            className="solid_button"
                            onClick={() => props.openWaveForm()}
                        >
                            Add A New Wave
                        </button>
                        <Table
                            columns={[
                                { header: '#', field: 'index' },
                                { header: 'Name', field: 'name' },
                                { header: 'Desc. Key', field: 'descKey' },
                                { header: 'Priority', field: 'priority' },
                                {
                                    header: 'Modifiers',
                                    field: 'modifiers',
                                },
                                { header: 'Entities', field: 'entities' },
                                {
                                    header: 'Action',
                                    field: 'action',
                                    resize: false,
                                },
                            ]}
                            data={waves.map((wave, waveIndex) => ({
                                index: waveIndex,
                                name: wave.name,
                                descKey: wave.descriptionKey,
                                priority: wave.priority,
                                modifiers: (
                                    <ModifierLabels
                                        ids={wave.modifierIds}
                                        setTooltip={setTooltipContent}
                                    />
                                ),
                                entities: (
                                    <EntityLabels
                                        ids={wave.entityIds}
                                        setTooltip={setTooltipContent}
                                    />
                                ),
                                action: (
                                    <>
                                        <button
                                            className="hollow_button table_button blue copy"
                                            onClick={() =>
                                                props.openWaveForm(wave)
                                            }
                                        >
                                            Copy
                                        </button>
                                        <Divider className="table_divider" />
                                        <button
                                            className="hollow_button table_button red delete"
                                            onClick={() =>
                                                props.deleteWave(waveIndex)
                                            }
                                        >
                                            Delete
                                        </button>
                                    </>
                                ),
                            }))}
                        />
                    </div>
                </Drawer>
                <Divider horizontal />
                <h3>Shop</h3>
                <div className="page_row bottom">
                    <FormFieldTitle title="Reset Time In Sec. (Float)">
                        <TextInput
                            type="float"
                            value={state[shopStates.RESET_TIME_IN_SECONDS]}
                            onChange={(value) =>
                                setState(
                                    shopStates.RESET_TIME_IN_SECONDS,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Gold Increase Per Re-Roll (Float)">
                        <TextInput
                            type="float"
                            value={state[shopStates.GOLD_INCREASE_PER_REROLL]}
                            onChange={(value) =>
                                setState(
                                    shopStates.GOLD_INCREASE_PER_REROLL,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Initial Re-Roll Count (Integer)">
                        <TextInput
                            type="int"
                            value={state[shopStates.INITIAL_REROLL_COUNT]}
                            onChange={(value) =>
                                setState(shopStates.INITIAL_REROLL_COUNT, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Initial Re-Roll Cost (Float)">
                        <TextInput
                            type="float"
                            value={state[shopStates.INITIAL_REROLL_COST]}
                            onChange={(value) =>
                                setState(shopStates.INITIAL_REROLL_COST, value)
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Initial Free Re-Rolls (Integer)">
                        <TextInput
                            type="int"
                            value={state[shopStates.INITIAL_FREE_REROLLS]}
                            onChange={(value) =>
                                setState(shopStates.INITIAL_FREE_REROLLS, value)
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Re-Roll Count Increase Time In Sec. (Float)">
                        <TextInput
                            type="float"
                            value={
                                state[
                                    shopStates
                                        .REROLL_COUNT_INCREASE_TIME_IN_SECONDS
                                ]
                            }
                            onChange={(value) =>
                                setState(
                                    shopStates.REROLL_COUNT_INCREASE_TIME_IN_SECONDS,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <div className="page_row bottom">
                    <FormFieldTitle title="Re-Roll Count Increase Amount (Integer)">
                        <TextInput
                            type="int"
                            value={
                                state[shopStates.REROLL_COUNT_INCREASE_AMOUNT]
                            }
                            onChange={(value) =>
                                setState(
                                    shopStates.REROLL_COUNT_INCREASE_AMOUNT,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                    <FormFieldTitle title="Re-Roll Cost Increase Per Re-Roll (Float)">
                        <TextInput
                            type="float"
                            value={
                                state[
                                    shopStates.REROLL_COST_INCREASE_PER_REROLL
                                ]
                            }
                            onChange={(value) =>
                                setState(
                                    shopStates.REROLL_COST_INCREASE_PER_REROLL,
                                    value
                                )
                            }
                        />
                    </FormFieldTitle>
                </div>
                <Divider horizontal />
                <h3>Showcase Assets</h3>
                <FormAssetFields
                    loading={props.loadingAssets}
                    selectedAssetsStateKey={
                        levelStates.GROUPED_SHOWCASE_ASSET_IDS
                    }
                />
                <div className="page_row right">
                    <button
                        className="solid_button blue"
                        onClick={props.createLevel}
                    >
                        Create Level
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LevelForm;
