import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import useWindowSize from '../../../providers/WindowProvider';
import SkinService from '../../../services/SkinService';
import ErrorUtils from '../../../utils/ErrorUtils';
import SkinForm from './SkinForm';
import SkinList from './SkinList';

function Skin() {
    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // Data
    const [skins, setSkins] = useState([]);
    const [loadingSkins, setLoadingSkins] = useState(true);

    // Functions
    const getSkins = async () => {
        setLoadingSkins(true);
        await SkinService.getAllSkins()
            .then((response) => {
                setSkins(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingSkins(false);
    };

    // Startup
    useEffect(() => {
        getSkins();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <SkinForm getSkins={getSkins} />
            <SkinList
                getSkins={getSkins}
                skins={skins}
                loading={loadingSkins}
            />
        </div>
    );
}

export default Skin;
