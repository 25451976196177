import React, { useContext, useEffect } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import TextInput from '../../common/text_input/TextInput';
import FileInput from '../../common/file_input/FileInput';
import Dropdown from '../../common/dropdown/Dropdown';
import TextBox from '../../common/text_box/TextBox';

function AssetForm(props) {
    // States
    const { state, setState } = useContext(StateContext);
    const assetStates = StateConstants.AssetStates;

    // Startup
    useEffect(() => {
        setState(StateConstants.AssetStates.IMAGE, undefined);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Asset</h2>
                <FormFieldTitle title="Asset Type">
                    <Dropdown
                        value={state[assetStates.TYPE]}
                        onChange={(value) =>
                            setState(StateConstants.AssetStates.TYPE, value)
                        }
                    >
                        {Object.entries(EnumerationConstants.AssetTypes).map(
                            ([type, values]) => (
                                <option key={type} value={type}>
                                    {values.title}
                                </option>
                            )
                        )}
                    </Dropdown>
                </FormFieldTitle>
                <FormFieldTitle title="Asset Reference (String)">
                    <div className="page_row">
                        <TextInput
                            value={state[assetStates.REFERENCE]}
                            onChange={(value) =>
                                setState(
                                    StateConstants.AssetStates.REFERENCE,
                                    value
                                )
                            }
                        />
                        <TextBox faded={!state[assetStates.TYPE]}>
                            {state[assetStates.TYPE]
                                ? `-${state[assetStates.TYPE]}`
                                : '-suffix'}
                        </TextBox>
                    </div>
                </FormFieldTitle>
                <FormFieldTitle title="Asset Image">
                    <FileInput
                        value={state[assetStates.IMAGE]}
                        onChange={(value) =>
                            setState(StateConstants.AssetStates.IMAGE, value)
                        }
                    />
                </FormFieldTitle>
                <div className="page_row right">
                    <button
                        className="solid_button blue"
                        onClick={props.createAsset}
                    >
                        Create Asset
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AssetForm;
