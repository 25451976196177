import { useContext } from 'react';

import FormAssetFields from '../../common/form_asset_fields/FormAssetFields';
import StateConstants from '../../../constants/StateConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateContext from '../../../providers/StateProvider';
import TextInput from '../../common/text_input/TextInput';
import Divider from '../../common/divider/Divider';

function TypeClassForm(props) {
    const { state, setState } = useContext(StateContext);
    const typeClassStates = StateConstants.TypeClassStates;

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Type Class</h2>
                <FormFieldTitle title="Type Class Name (String)">
                    <TextInput
                        value={state[typeClassStates.NAME]}
                        onChange={(value) =>
                            setState(typeClassStates.NAME, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={state[typeClassStates.DESC_KEY]}
                        onChange={(value) =>
                            setState(typeClassStates.DESC_KEY, value)
                        }
                    />
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Assets</h3>
                <FormAssetFields
                    loading={props.loadingAssets}
                    selectedAssetsStateKey={typeClassStates.GROUPED_ASSET_IDS}
                />
                <div className="page_row right">
                    <button
                        className="solid_button blue"
                        onClick={props.createTypeClass}
                    >
                        Create Type Class
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TypeClassForm;
