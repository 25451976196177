import { useContext, useEffect, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import NotificationContext from '../../../providers/NotificationProvider';
import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import AssetService from '../../../services/AssetService';
import SkinService from '../../../services/SkinService';
import AssetUtils from '../../../utils/AssetUtils';
import ErrorUtils from '../../../utils/ErrorUtils';
import SkinUtils from '../../../utils/SkinUtils';
import DataUtils from '../../../utils/DataUtils';
import TextInput from '../../common/text_input/TextInput';
import Divider from '../../common/divider/Divider';
import Gallery from '../../common/gallery/Gallery';
import Drawer from '../../common/drawer/Drawer';

const SkinForm = (props) => {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Data
    const [skinName, setSkinName] = useState('');
    const [skinAssetIds, setSkinAssetIds] = useState([]);

    const [loadingAssets, setLoadingAssets] = useState(true);
    const [assets, setAssets] = useState([]);
    const [selectedIconAssetIds, setSelectedIconAssetIds] = useState([]);
    const [selectedCostAssetIds, setSelectedCostAssetIds] = useState([]);
    const [selectedVisEffAssetIds, setSelectedVisEffAssetIds] = useState([]);
    const [selectedTraEffAssetIds, setSelectedTraEffAssetIds] = useState([]);
    const [selectedColEffAssetIds, setSelectedColEffAssetIds] = useState([]);
    const [selectedDepEffAssetIds, setSelectedDepEffAssetIds] = useState([]);
    const [iconAssetSearch, setIconAssetSearch] = useState('');
    const [costAssetSearch, setCostAssetSearch] = useState('');
    const [visEffAssetSearch, setVisEffAssetSearch] = useState('');
    const [traEffAssetSearch, setTraEffAssetSearch] = useState('');
    const [colEffAssetSearch, setColEffAssetSearch] = useState('');
    const [depEffAssetSearch, setDepEffAssetSearch] = useState('');

    const assetTypeAndStateMapping = {
        icon: {
            selectedState: selectedIconAssetIds,
            selectedSetter: setSelectedIconAssetIds,
            searchState: iconAssetSearch,
            searchSetter: setIconAssetSearch,
        },
        costume: {
            selectedState: selectedCostAssetIds,
            selectedSetter: setSelectedCostAssetIds,
            searchState: costAssetSearch,
            searchSetter: setCostAssetSearch,
        },
        visualEffect: {
            selectedState: selectedVisEffAssetIds,
            selectedSetter: setSelectedVisEffAssetIds,
            searchState: visEffAssetSearch,
            searchSetter: setVisEffAssetSearch,
        },
        trailEffect: {
            selectedState: selectedTraEffAssetIds,
            selectedSetter: setSelectedTraEffAssetIds,
            searchState: traEffAssetSearch,
            searchSetter: setTraEffAssetSearch,
        },
        collisionEffect: {
            selectedState: selectedColEffAssetIds,
            selectedSetter: setSelectedColEffAssetIds,
            searchState: colEffAssetSearch,
            searchSetter: setColEffAssetSearch,
        },
        departureEffect: {
            selectedState: selectedDepEffAssetIds,
            selectedSetter: setSelectedDepEffAssetIds,
            searchState: depEffAssetSearch,
            searchSetter: setDepEffAssetSearch,
        },
    };

    // Functions
    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => {
                setAssets(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingAssets(false);
    };

    const createSkin = async () => {
        const notificationId = raiseNotification('Creating skin...', {
            permanent: true,
        });
        const data = SkinUtils.createSkinDTO(skinName, skinAssetIds);
        await SkinService.createSkin(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Skin was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                props.getSkins();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    useEffect(() => {
        setSkinAssetIds(
            [
                selectedIconAssetIds,
                selectedCostAssetIds,
                selectedVisEffAssetIds,
                selectedTraEffAssetIds,
                selectedColEffAssetIds,
                selectedDepEffAssetIds,
            ].flat()
        );
    }, [
        selectedIconAssetIds,
        selectedCostAssetIds,
        selectedVisEffAssetIds,
        selectedTraEffAssetIds,
        selectedColEffAssetIds,
        selectedDepEffAssetIds,
    ]);

    // Startup
    useEffect(() => {
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Skin</h2>
                <FormFieldTitle title="Skin Name (String)">
                    <TextInput onChange={setSkinName} />
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Assets</h3>
                {Object.entries(EnumerationConstants.AssetTypes).map(
                    ([assetType, assetTypeContent], index) => (
                        <FormFieldTitle
                            key={index}
                            title={`${assetTypeContent.title} Asset`}
                        >
                            <Drawer
                                text={
                                    assetTypeAndStateMapping[assetType]
                                        .selectedState.length > 0
                                        ? `Selected ${assetTypeContent.title.toLowerCase()} asset ID: ${
                                              assetTypeAndStateMapping[
                                                  assetType
                                              ].selectedState[0]
                                          }`
                                        : CommonConstants.NOT_SELECTED_TEXT
                                }
                            >
                                <div className="page_grid">
                                    {!loadingAssets && (
                                        <FormFieldTitle title="Search By Reference">
                                            <TextInput
                                                onChange={
                                                    assetTypeAndStateMapping[
                                                        assetType
                                                    ].searchSetter
                                                }
                                            />
                                        </FormFieldTitle>
                                    )}
                                    <Gallery
                                        loading={loadingAssets}
                                        selectionLimit={1}
                                        data={DataUtils.groupItemsBySelection(
                                            AssetUtils.createGalleryData(
                                                AssetUtils.filterAssets(
                                                    assets,
                                                    assetTypeAndStateMapping[
                                                        assetType
                                                    ].searchState,
                                                    assetType
                                                )
                                            ),
                                            assetTypeAndStateMapping[assetType]
                                                .selectedState
                                        ).flat()}
                                        selectedItems={
                                            assetTypeAndStateMapping[assetType]
                                                .selectedState
                                        }
                                        onSelect={
                                            assetTypeAndStateMapping[assetType]
                                                .selectedSetter
                                        }
                                    />
                                </div>
                            </Drawer>
                        </FormFieldTitle>
                    )
                )}
                <div className="page_row right">
                    <button className="solid_button blue" onClick={createSkin}>
                        Create Skin
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SkinForm;
