import { useContext, useState } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import CommonConstants from '../../../constants/CommonConstants';
import FormFieldTitle from '../form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import AssetUtils from '../../../utils/AssetUtils';
import TextInput from '../text_input/TextInput';
import Gallery from '../gallery/Gallery';
import Drawer from '../drawer/Drawer';

function FormAssetFields(props) {
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [assetFilterReferences, setAssetFilterReferences] = useState(
        Object.keys(EnumerationConstants.AssetTypes).map((assetType) => [
            assetType,
            '',
        ])
    );

    return (
        <>
            {Object.entries(EnumerationConstants.AssetTypes).map(
                ([assetType, { title: assetTypeTitle }], index) => {
                    const selectedItems =
                        state[props.selectedAssetsStateKey][assetType];
                    const formFieldTitle = `${assetTypeTitle} Asset`;
                    const drawerText =
                        selectedItems.length > 0
                            ? `Selected ${assetTypeTitle.toLowerCase()} asset ID: ${
                                  selectedItems[0]
                              }`
                            : CommonConstants.NOT_SELECTED_TEXT;
                    const onSearchChange = (value) => {
                        setAssetFilterReferences((prevState) => ({
                            ...prevState,
                            [assetType]: value,
                        }));
                    };
                    const galleryData = AssetUtils.createGalleryData(
                        AssetUtils.filterAssets(
                            state[commonStates.ASSETS],
                            assetFilterReferences[assetType],
                            assetType
                        )
                    );
                    const onGallerySelect = (value) => {
                        setState(props.selectedAssetsStateKey, {
                            ...state[props.selectedAssetsStateKey],
                            [assetType]: value,
                        });
                    };

                    return (
                        <FormFieldTitle key={index} title={formFieldTitle}>
                            <Drawer text={drawerText}>
                                <div className="page_grid">
                                    {!props.loading && (
                                        <FormFieldTitle title="Search By Reference">
                                            <TextInput
                                                onChange={onSearchChange}
                                            />
                                        </FormFieldTitle>
                                    )}
                                    <Gallery
                                        loading={props.loading}
                                        data={galleryData}
                                        selectedItems={selectedItems}
                                        onSelect={onGallerySelect}
                                        selectionLimit={1}
                                        selectedFirst
                                    />
                                </div>
                            </Drawer>
                        </FormFieldTitle>
                    );
                }
            )}
        </>
    );
}

export default FormAssetFields;
