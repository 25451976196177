import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import AssetDetailModal from './asset_detail_modal/AssetDetailModal';
import StateConstants from '../../../constants/StateConstants';
import useWindowSize from '../../../providers/WindowProvider';
import StateContext from '../../../providers/StateProvider';
import AssetService from '../../../services/AssetService';
import ErrorUtils from '../../../utils/ErrorUtils';
import AssetUtils from '../../../utils/AssetUtils';
import AssetForm from './AssetForm';
import AssetList from './AssetList';

function Asset() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // Layout
    const windowSize = useWindowSize();
    const isScreenSmall = windowSize.width < 1000;
    const [verticalPage, setVerticalPage] = useState(isScreenSmall);
    useEffect(() => setVerticalPage(isScreenSmall), [isScreenSmall]);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const assetStates = StateConstants.AssetStates;
    const [loadingAssets, setLoadingAssets] = useState(true);
    const [selectedAssetId, setSelectedAssetId] = useState(undefined);
    const selectedAsset = selectedAssetId
        ? state[commonStates.ASSETS].find((item) => item.id === selectedAssetId)
        : undefined;

    // Functions
    const getAssets = async () => {
        setLoadingAssets(true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setLoadingAssets(false);
    };

    const createAsset = async () => {
        const notificationId = raiseNotification('Creating asset...', {
            permanent: true,
        });
        const data = await AssetUtils.createAssetDTO(
            state[assetStates.TYPE],
            state[assetStates.REFERENCE],
            state[assetStates.IMAGE]
        );
        AssetService.createAsset(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Asset was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                getAssets();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const deleteAsset = (assetId) => {
        const notificationId = raiseNotification('Deleting asset...', {
            permanent: true,
        });
        AssetService.deleteAsset(assetId)
            .then(() => {
                setSelectedAssetId(undefined);
                removeNotification(notificationId);
                raiseNotification('Asset was deleted.', {
                    type: 'success',
                });
            })
            .catch((error) => {
                setSelectedAssetId(undefined);
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            })
            .finally(() => {
                getAssets();
            });
    };

    // Startup
    useEffect(() => {
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className={verticalPage ? 'page vertical' : 'page horizontal'}>
            <AssetDetailModal asset={selectedAsset} deleteAsset={deleteAsset} />
            <AssetForm createAsset={createAsset} />
            <AssetList
                loadingAssets={loadingAssets}
                setAssetId={setSelectedAssetId}
            />
        </div>
    );
}

export default Asset;
