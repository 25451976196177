import { useContext } from 'react';

import EnumerationConstants from '../../../constants/EnumerationConstants';
import FormAssetFields from '../../common/form_asset_fields/FormAssetFields';
import FormFieldTitle from '../../common/form_field_title/FormFieldTitle';
import StateConstants from '../../../constants/StateConstants';
import StateContext from '../../../providers/StateProvider';
import TextInput from '../../common/text_input/TextInput';
import Dropdown from '../../common/dropdown/Dropdown';
import Divider from '../../common/divider/Divider';

const ModifierForm = (props) => {
    const { state, setState } = useContext(StateContext);
    const modifierStates = StateConstants.ModifierStates;

    return (
        <div className="page_section limit_width divider_border">
            <div className="page_grid">
                <h2>Create Modifier</h2>
                <FormFieldTitle title="Modifier Name (String)">
                    <TextInput
                        value={state[modifierStates.NAME]}
                        onChange={(value) =>
                            setState(modifierStates.NAME, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Description Key (String)">
                    <TextInput
                        value={state[modifierStates.DESC_KEY]}
                        onChange={(value) =>
                            setState(modifierStates.DESC_KEY, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Modifier Type">
                    <Dropdown
                        value={state[modifierStates.TYPE]}
                        onChange={(value) =>
                            setState(modifierStates.TYPE, value)
                        }
                    >
                        {Object.entries(EnumerationConstants.ModifierTypes).map(
                            ([type, title]) => (
                                <option key={type} value={type}>
                                    {title}
                                </option>
                            )
                        )}
                    </Dropdown>
                </FormFieldTitle>
                <FormFieldTitle title="Expiration (Float)">
                    <TextInput
                        type="float"
                        value={state[modifierStates.EXPIRATION]}
                        onChange={(value) =>
                            setState(modifierStates.EXPIRATION, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Value (Float)">
                    <TextInput
                        type="float"
                        value={state[modifierStates.VALUE]}
                        onChange={(value) =>
                            setState(modifierStates.VALUE, value)
                        }
                    />
                </FormFieldTitle>
                <FormFieldTitle title="Price (Float)">
                    <TextInput
                        type="float"
                        value={state[modifierStates.PRICE]}
                        onChange={(value) =>
                            setState(modifierStates.PRICE, value)
                        }
                    />
                </FormFieldTitle>
                <Divider horizontal />
                <h3>Assets</h3>
                <FormAssetFields
                    loading={props.loadingAssets}
                    selectedAssetsStateKey={modifierStates.GROUPED_ASSET_IDS}
                />
                <div className="page_row right">
                    <button
                        className="solid_button blue"
                        onClick={props.createModifier}
                    >
                        Create Modifier
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModifierForm;
