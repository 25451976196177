import DataUtils from './DataUtils';

class SkinUtils {
    static createSkinDTO(skinName, skinAssetIds) {
        return {
            name: DataUtils.setString(skinName),
            assetIds: skinAssetIds,
        };
    }
}

export default SkinUtils;
