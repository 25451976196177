import DataUtils from './DataUtils';

class ModifierUtils {
    static createModifierDTO(
        modifierName,
        modifierDescriptionKey,
        modifierType,
        modifierExpiration,
        modifierValue,
        modifierPrice,
        modifierAssetIds
    ) {
        return {
            name: DataUtils.setString(modifierName),
            descriptionKey: DataUtils.setString(modifierDescriptionKey),
            modifierType: DataUtils.setString(modifierType),
            expiration: DataUtils.setFloat(modifierExpiration),
            value: DataUtils.setFloat(modifierValue),
            price: DataUtils.setFloat(modifierPrice),
            assetIds: modifierAssetIds,
        };
    }

    static createModifierLabelData(modifier) {
        return [
            { title: 'ID', value: modifier.id },
            { title: 'Modifier Name', value: modifier.name },
            { title: 'Desc. Key', value: modifier.descriptionKey },
            { title: 'Modifier Type', value: modifier.modifierType },
            { title: 'Expiration', value: modifier.expiration },
            { title: 'Value', value: modifier.value },
            { title: 'Price', value: modifier.price },
            { title: 'Created At', value: modifier.createdAt },
        ];
    }
}

export default ModifierUtils;
