import axios from 'axios';

import AuthService from './AuthService';

class TypeClassService {
    static async createTypeClass(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/typeClasses', data);
        return response;
    }

    static async getAllTypeClasses() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/typeClasses');
        return response;
    }
}

export default TypeClassService;
