import { useState } from 'react';

import { FaPen, FaXmark } from 'react-icons/fa6';

import TextBox from '../../common/text_box/TextBox';
import TextInput from '../../common/text_input/TextInput';

import './Settings.css';

function SettingsList(props) {
    const [name, setName] = useState('');
    const [changeName, setChangeName] = useState(false);
    const [password, setPassword] = useState('');
    const [changePassword, setChangePassword] = useState(false);

    const handleNameChange = () => {
        console.log(name);
    };

    const handlePasswordChange = () => {
        console.log(password);
    };

    return (
        <div className="page_section limit_width divider_border">
            {props.loading ? (
                <></>
            ) : (
                <div className="page_grid">
                    <div className="data_box">
                        <p className="user_data_field">ID</p>
                        <TextBox wrap flex>
                            {props.user.id}
                        </TextBox>
                    </div>
                    <div className="data_box">
                        <p className="user_data_field">Email</p>
                        <TextBox wrap>{props.user.email}</TextBox>
                    </div>
                    <div className="data_box">
                        <p className="user_data_field">Name</p>
                        {changeName ? (
                            <div className="user_data_row">
                                <div className="flex">
                                    <TextInput
                                        id="userName"
                                        label="Enter a new user name."
                                        value={props.user.name}
                                        onChange={setName}
                                    />
                                </div>
                                <button
                                    className="solid_button blue"
                                    onClick={handleNameChange}
                                >
                                    Save
                                </button>
                                <button
                                    className="solid_button red"
                                    onClick={() => setChangeName(false)}
                                >
                                    <FaXmark />
                                </button>
                            </div>
                        ) : (
                            <div className="user_data_row">
                                <div className="flex">
                                    <TextBox wrap>{props.user.name}</TextBox>
                                </div>
                                <button
                                    className="solid_button blue"
                                    onClick={() => setChangeName(true)}
                                >
                                    <FaPen />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="data_box">
                        <p className="user_data_field">Password</p>
                        {changePassword ? (
                            <div className="user_data_row">
                                <div className="flex">
                                    <TextInput
                                        id="userPassword"
                                        label="Enter a new password."
                                        onChange={setPassword}
                                        password
                                    />
                                </div>
                                <button
                                    className="solid_button blue"
                                    onClick={handlePasswordChange}
                                >
                                    Save
                                </button>
                                <button
                                    className="solid_button red"
                                    onClick={() => setChangePassword(false)}
                                >
                                    <FaXmark />
                                </button>
                            </div>
                        ) : (
                            <div className="user_data_row">
                                <div className="flex">
                                    <TextBox faded wrap>
                                        **********
                                    </TextBox>
                                </div>
                                <button
                                    className="solid_button blue"
                                    onClick={() => setChangePassword(true)}
                                >
                                    <FaPen />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SettingsList;
