import { useEffect, useRef, useState } from 'react';

import { FaXmark } from 'react-icons/fa6';

import './Modal.css';

function Modal(props) {
    const modalRef = useRef();
    const [visible, setVisible] = useState(Boolean(props.visible));
    const [dragActive, setDragActive] = useState(false);

    const setModalClassName = () => {
        return `modal_bg ${props.draggable ? 'draggable' : ''} ${
            visible ? '' : 'hidden'
        }`.trim();
    };

    useEffect(() => setVisible(Boolean(props.visible)), [props.visible]);

    useEffect(() => {
        let mouseStartCoord, modalStartCoord;

        const handleModalTransform = (e) => {
            const setCoordValue = (newValue, { min = 0, max = 0 }) => {
                if (newValue < min) {
                    return min;
                } else if (newValue > max) {
                    return max;
                } else {
                    return newValue;
                }
            };
            if (!mouseStartCoord) {
                mouseStartCoord = { x: e.clientX, y: e.clientY };
            }
            if (!modalStartCoord) {
                modalStartCoord = {
                    x: parseInt(modalRef.current.style.left),
                    y: parseInt(modalRef.current.style.top),
                };
            }
            if (modalRef.current) {
                const mouseDelta = {
                    x: e.clientX - mouseStartCoord.x,
                    y: e.clientY - mouseStartCoord.y,
                };
                const newModalCoord = {
                    x: setCoordValue(modalStartCoord.x + mouseDelta.x, {
                        min: 0,
                        max: window.innerWidth - modalRef.current.offsetWidth,
                    }),
                    y: setCoordValue(modalStartCoord.y + mouseDelta.y, {
                        min: 0,
                        max: window.innerHeight - modalRef.current.offsetHeight,
                    }),
                };
                modalRef.current.style.left = `${newModalCoord.x}px`;
                modalRef.current.style.top = `${newModalCoord.y}px`;
            }
        };

        const stopModalTransform = () => {
            setDragActive(false);
            document.removeEventListener('mousemove', handleModalTransform);
            document.removeEventListener('mouseup', stopModalTransform);
            mouseStartCoord = undefined;
            modalStartCoord = undefined;
        };

        if (props.visible && dragActive) {
            document.addEventListener('mousemove', handleModalTransform);
            document.addEventListener('mouseup', stopModalTransform);
        }
    }, [dragActive, props.visible]);

    return (
        <div
            ref={modalRef}
            className={setModalClassName()}
            style={props.draggable && { left: 10, top: 10 }}
        >
            <div className="modal">
                <div
                    className="modal_header"
                    onMouseDown={() => setDragActive(true)}
                >
                    <FaXmark
                        className="close_icon modal_close_icon"
                        onClick={() => {
                            setVisible(false);
                            props.onClose && props.onClose(false);
                        }}
                    />
                    {props.title && (
                        <h4 className="modal_title">{props.title}</h4>
                    )}
                </div>
                <div className="modal_content">{props.children}</div>
            </div>
        </div>
    );
}

export default Modal;
