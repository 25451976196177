// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_list {
    z-index: 1000;
    position: fixed;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
    right: 0;
    bottom: 0;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 300px;
    padding: 10px;
    gap: 5px;
    pointer-events: none;
}

.notification {
    display: flex;
    align-items: start;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
    color: black;
    background-color: whitesmoke;
    font-size: 1rem;
    word-wrap: break-word;
    word-break: break-word;
    pointer-events: auto;
}

.notification_icon {
    color: rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

.notification.success {
    color: white;
    background-color: mediumseagreen;
}

.notification.warning {
    color: black;
    background-color: gold;
}

.notification.error {
    color: white;
    background-color: tomato;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/toast_notification/ToastNotification.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,QAAQ;IACR,SAAS;IACT,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,QAAQ;IACR,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,4BAA4B;IAC5B,eAAe;IACf,qBAAqB;IACrB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,2BAAsB;IAAtB,sBAAsB;IACtB,4BAAuB;IAAvB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,wBAAwB;AAC5B","sourcesContent":[".notification_list {\n    z-index: 1000;\n    position: fixed;\n    overflow: hidden;\n    display: flex;\n    align-items: end;\n    justify-content: end;\n    flex-direction: column;\n    right: 0;\n    bottom: 0;\n    width: fit-content;\n    max-width: 300px;\n    padding: 10px;\n    gap: 5px;\n    pointer-events: none;\n}\n\n.notification {\n    display: flex;\n    align-items: start;\n    height: fit-content;\n    width: fit-content;\n    padding: 10px;\n    gap: 10px;\n    border-radius: 8px;\n    color: black;\n    background-color: whitesmoke;\n    font-size: 1rem;\n    word-wrap: break-word;\n    word-break: break-word;\n    pointer-events: auto;\n}\n\n.notification_icon {\n    color: rgba(0, 0, 0, 0.2);\n    font-size: 1rem;\n    min-width: fit-content;\n    min-height: fit-content;\n}\n\n.notification.success {\n    color: white;\n    background-color: mediumseagreen;\n}\n\n.notification.warning {\n    color: black;\n    background-color: gold;\n}\n\n.notification.error {\n    color: white;\n    background-color: tomato;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
